/**
 *
 * IDAMLoginSuccessPage
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { compose } from '@reduxjs/toolkit';
import { Redirect, useHistory } from 'react-router-dom';

import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { makeSelectAuthedUser } from 'containers/App/selectors';

import session from 'services/session';
import { RequestStatus } from 'shared/constants';

import useFeatureFlags from 'utils/featureFlags/useFeatureFlags';
import { IDAMPostLoginSuccess } from './actions';
import reducer from './reducer';
import saga from './saga';
import makeSelectIDAMLoginSuccessPage from './selectors';

export function IDAMLoginSuccessPage({ authedUser, onIDAMLoginSuccess }) {
    useInjectReducer({ key: 'idamLoginSuccessPage', reducer });
    useInjectSaga({ key: 'idamLoginSuccessPage', saga });

    const history = useHistory();
    const featureFlags = useFeatureFlags();

    useEffect(() => {
        if (authedUser.loadState === RequestStatus.INIT) {
            onIDAMLoginSuccess(history);
        }
    }, [authedUser, onIDAMLoginSuccess, history]);

    if (session.isAuthenticated()) {
        return featureFlags.showSummaryDashboard ? <Redirect to="/dashboard" /> : <Redirect to="/campaigns" />;
    }

    return <div />;
}

IDAMLoginSuccessPage.propTypes = {
    authedUser: PropTypes.object.isRequired,
    onIDAMLoginSuccess: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    idamLoginSuccessPage: makeSelectIDAMLoginSuccessPage(),
    authedUser: makeSelectAuthedUser(),
});

function mapDispatchToProps(dispatch) {
    return {
        onIDAMLoginSuccess: (history) => dispatch(IDAMPostLoginSuccess(history)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IDAMLoginSuccessPage);
