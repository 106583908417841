import React from 'react';

export const ResourceOnsiteDisplay = () => (
    <>
        <br />
        <br />
        <h3>Display.Ads - Onsite Display &amp; Video</h3>
        <br />
        <p>
            Onsite Display Ads tell your brand story through eye-catching visuals and reaches your unique audience at
            any point in their shopping journey on BestBuy.com.
        </p>
        <br />
        <br />
        <h3>Report Views </h3>
        <br />
        <p>
            <b>Summary Page </b>
            <ul>
                <li>
                    This page shows the daily performance of the campaign and includes line graphs of common KPIs to
                    show trends over time.
                </li>
                <li>
                    This is also where you will see total campaign recaps for the time period the report was pulled.
                </li>
                <li>
                    Most common questions can be answered from this page such as what the total ROAS is, CTR, and what
                    happened on a particular day or over a report time period.
                </li>
                <li>
                    For Onsite Video campaigns, the summary page will include video-specific metrics such as Video
                    Starts, VCR, and quartiles.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Creative View </b>
            <ul>
                <li>
                    This view shows performance at a line-item level based on Creative ID. This is useful for
                    understanding which creative message, size, or style performed best using view-through or
                    click-through metrics.
                </li>
                <li>
                    Creative Name Taxonomy includes Campaign Name, Ad Size, Ad Format, Flight Dates, Flight Message, and
                    Trafficking Version. Each is separated by an underscore.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Placement View </b>
            <ul>
                <li>
                    This view shows performance at a line-item level based on Placement ID. This view is helpful to
                    understand which vehicles (desktop, mobile, app) performed best on each metric, or which targeting
                    tactics (behavioral, contextual, etc.) were most successful.
                </li>
                <li>
                    Placement Name Taxonomy includes Vehicle, Placement Type, Targeting Tactic, Targeting Type,
                    Targeting Subtype, Wave, Ad Size, Agency ID, Category, and Rate Type. Each is separated by an
                    underscore.
                </li>
                <li>
                    Targeting can include Behavioral which leverages historical shopper navigation plus transactional
                    data to target and tailor creative message. Contextual which reaches shoppers as they are actively
                    shopping within our product categories. ROS – Run of Site contains no form of targeting, and the ad
                    can run essentially anywhere on the site.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Product View </b>
            <br />
            <li>
                This view shows performance at a SKU level. This view does not have impressions, clicks, CTR, ad spend,
                or ROAS as this view is strictly based on attribution and not delivery metrics. This view is helpful for
                understanding which SKUs were ultimately considered or purchased after viewing or clicking on a campaign
                ad.
            </li>
            <li>
                Attributes related to the Best Buy Product hierarchy, such as Brand, Department, Class and Subclass are
                additional columns you may add to reporting using the Modify Columns feature when viewing a campaign.
            </li>
        </p>
        <br />
        <br />
        <h3>Report Glossary </h3>
        <br />
        <p>
            <b>Impression: </b>
            Impression = a page load. An impression is counted each time a creative is downloaded in the user&lsquo;s
            device and has begun to load.
        </p>
        <br />
        <p>
            <b>Delivered Spend: </b>
            The spend attributed to that placement or creative line item.
        </p>
        <br />
        <p>
            <b>Click: </b>
            When a user clicks on an ad creative.
        </p>
        <br />
        <p>
            <b>CTR: </b>
            Ratio of clicks divided by impressions to indicate click through rate.
        </p>
        <br />
        <p>
            <b>PDP Views: </b>
            The number of times a customer viewed a reporting SKU PDP page after viewing a campaign creative.
        </p>
        <br />
        <p>
            <b>Add to Cart: </b>
            The number of times a customer added a reporting SKU to their cart after viewing a campaign creative.
        </p>
        <br />
        <p>
            <b>Online Revenue: </b>
            The total online revenue (ecommerce) that are attributed to a Click or View Ad event, as a result of
            matching both enterprise customer identifiers and google ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>Online Units: </b>
            The total number of online units sold (ecommerce) attributed to a Click or View Ad event, as a result of
            matching both enterprise customer identifiers and google ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>In Store Revenue: </b>
            The total in store revenue attributed to a Click or View Ad event, as a result of matching both enterprise
            customer identifiers and ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>In Store Units: </b>
            The total number of in store units sold that are attributed to a Click or View Ad event, as a result of
            matching both enterprise customer identifiers and google ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>Online ROAS: </b>
            Ratio of total online revenue (ecommerce) attributed to a Click or View Ad event divided by the spend
            attributed to that placement or creative line item. Available as a dollar amount or percentage.
        </p>
        <br />
        <p>
            <b>In Store ROAS: </b>
            Ratio of total in store revenue attributed to a Click or View Ad event divided by the spend attributed to
            that placement or creative line item. Available as a dollar amount or percentage.
        </p>
        <br />
        <p>
            <b>CPU: </b>
            Ratio of Ad Spend divided by Total Units sold to indicate Cost Per Unit.
        </p>
        <br />
        <p>
            <b>Video Starts: </b>
            The number of times a video has been started. This is logged once per view, such that if a person stops
            playing video and then restarts it, the restart isn&apos;t counted. In a very small number of cases, there
            may be trailing video starts, etc. that trickle in after the campaign end date. This is due to video still
            being &quot;live&quot; for people with browsers left open, and then the video starts after the campaign end
            date.
        </p>
        <br />
        <p>
            <b>Video Completion Rate: </b>
            The percentage of video views that were played to 100%.
        </p>
        <br />
        <p>
            <b>25% Complete: </b>
            The number of times a video has been played to or past 25% of its entire length.
        </p>
        <br />
        <p>
            <b>50% Complete: </b>
            The number of times a video has been played to or past 50% of its entire length.
        </p>
        <br />
        <p>
            <b>75% Complete: </b>
            The number of times a video has been played to or past 75% of its entire length.
        </p>
        <br />
        <p>
            <b>100% Complete: </b>
            The number of times a video has been played to or past 100% of its entire length.
        </p>
        <br />
        <br />
        <h3>Attribution </h3>
        <br />
        <p>
            <b>How do we attribute sales for online and in store purchases? </b>
            <br />
            We use a first party customer identifier to track unique customers across devices on our website. Then we
            match back any sales from these customers within our 14-day attribution window for in-store (over 80% match
            up to 99% in select categories) or online (99%+ match).
            <br />
            <br />
            PDP views, add to carts, and purchases are divided based on the impressions the customer had in the last 14
            days. This attribution is weighted by impression across line items and campaigns (onsite display and video).
            The weighting also applies between onsite display and onsite video ad products.
        </p>
        <br />
        <p>
            <b>Click-through Attribution</b> - Conversions generated post-click
            <br />
            <ul>
                <li>
                    Attribution: Each time a creative is clicked on the user&lsquo;s device after which a purchase is
                    made within 14 days.
                </li>
                <li>These metrics are evenly weighted across all clicked on placements and creatives.</li>
                <li>Includes a 14-day lookback window. </li>
            </ul>
        </p>
        <br />
        <p>
            <b>View-through Attribution</b> - Conversions generated post-impression
            <br />
            <ul>
                <li>
                    Attribution: Each time a creative is downloaded on the user&lsquo;s device and has begun to load
                    after which a purchase is made within 14 days.
                </li>
                <li>These metrics are evenly weighted across placements and creatives.</li>
                <li>Includes a 14-day lookback window.</li>
                <li>View-through attribution is inclusive of click-through attribution. </li>
            </ul>
        </p>
        <br />
        <br />
        <h3>Reporting FAQs </h3>
        <br />
        <p>
            <b>For what timeframe can I see reporting? </b>
            <br />
            Onsite Display and Video data is available from February 1, 2021 and onward.
        </p>
        <br />
        <p>
            <b>What metrics align to what goals? </b>
            <br />
            <ul>
                <li>Awareness = Impressions</li>
                <li>Consideration = Clicks, CTR, PDP Views, Add to Cart</li>
                <li>Conversion = Revenue, Units, ROAS, CPU</li>
            </ul>
        </p>
        <br />
        <p>
            <b>What CTR or ROAS can we expect to see from our campaign? </b>
            <br />
            Each campaign&lsquo;s performance can vary due to a variety of factors. Here are some things to consider:
            <br />
            <br />
            <ol>
                <li>
                    <b>Dates the campaign is live</b>
                    <br />
                    Secondary holiday timeframes (Memorial Day, Labor Day, etc.), or product launch dates, for example,
                    may impact campaign performance.
                </li>
                <br />
                <li>
                    <b>Reporting SKUs selected</b>
                    <br />
                    Including the most SKUs possible will typically result in higher ROAS. It is always recommended to
                    include the most amount of SKUs possible.
                </li>
                <br />
                <li>
                    <b>ASP of reporting SKUs</b>
                    <br />
                    Higher priced items generally result in higher ROAS as the SKU price is relative to delivered spend
                    (and the same correlation will be seen with lower priced items).
                </li>
                <br />
                <li>
                    <b>Size of potential purchaser audience</b>
                    <br />
                    Accessory SKUs typically require ownership of specific hardware to use them which may result in
                    lower ROAS and CTR. For example, you need a certain DSLR camera to use a certain lens, or an iPhone
                    to use an iPhone case.
                </li>
                <br />
                <li>
                    <b>High-impact placements and selected targeting tactics</b>
                </li>
            </ol>
            <ul>
                <li>
                    High-impact units typically have higher CTR and lower ROAS compared to standard placements as they
                    are higher funnel placements.
                </li>
                <li>
                    Contextual targeting tends to have higher ROAS compared to other targeting tactics due to being a
                    lower funnel targeting strategy.
                </li>
                <li>
                    Mobile App and Mobile web tend to have higher CTR, but lower ROAS compared to Desktop placements as
                    we observe customers tend to browse on mobile, but purchase on desktop.
                </li>
            </ul>
        </p>
        <br />
        <br />
    </>
);
