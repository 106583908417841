import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import AlertSvg from 'components/Svg/Alert';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.lightPink,
        paddingLeft: 40,
        paddingRight: 40,
        minHeight: 64,
        borderTop: `2px solid white`,
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 24,
    },
    alertIcon: {
        paddingRight: 24,
        justifyItems: 'center',
    },
    dismissIcon: {
        padding: 0,
    },
    message: {
        fontFamily: 'humanBBYDigital',
    },
    bold: {
        fontWeight: 700,
        fontFamily: 'humanBBYDigital',
    },
    minor: {
        backgroundColor: theme.palette.brightGray,
    },
    improvement: {
        backgroundColor: theme.palette.lightBlue,
    },
    generalUpdate: {
        backgroundColor: theme.palette.lightGreen,
    },
    critical: {
        backgroundColor: theme.palette.lightPink,
    },
    anchor: {
        fontWeight: 700,
        color: theme.palette.techBlack,
        fontFamily: 'humanBBYDigital',
    },
}));

export const AlertBannerType = {
    CRITICAL: 1,
    MINOR: 2,
    IMPROVEMENT: 3,
    GENERALUPDATE: 4,
};

const Banner = ({ id, alertLevel, dismissEnabled, url, title, message, onClose }) => {
    const classes = useStyles();

    const getBackgroundColor = (bannerType) => {
        switch (bannerType) {
            case AlertBannerType.GENERALUPDATE:
                return classes.generalUpdate;
            case AlertBannerType.IMPROVEMENT:
                return classes.improvement;
            case AlertBannerType.MINOR:
                return classes.minor;
            case AlertBannerType.CRITICAL:
            default:
                return classes.critical;
        }
    };

    return (
        <div
            data-testid={`alertBanner-${id}`}
            className={classnames(classes.container, getBackgroundColor(alertLevel))}
        >
            <div className={classes.leftContainer}>
                {alertLevel === AlertBannerType.CRITICAL && (
                    <div className={classes.alertIcon}>
                        <AlertSvg width={36} height={36} />
                    </div>
                )}
                <span>
                    <span className={classes.bold}>{title}:</span> <span className={classes.message}>{message} </span>
                    {url && (
                        <a className={classes.anchor} href={url} target="black" rel="noopener">
                            Learn More
                        </a>
                    )}
                </span>
            </div>
            {dismissEnabled && (
                <IconButton
                    data-testid={`dismissIcon-${id}`}
                    className={classes.dismissIcon}
                    onClick={() => onClose(id)}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </div>
    );
};

Banner.propTypes = {
    id: PropTypes.number.isRequired,
    alertLevel: PropTypes.number.isRequired,
    dismissEnabled: PropTypes.bool.isRequired,
    url: PropTypes.string,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func,
};

export default Banner;
