import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    makeStyles,
} from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: '16px 24px',
    },
    dialogButton: {
        margin: '0',
    },
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    formButtonCancel: {
        backgroundColor: theme.palette.battleGray,
        color: theme.palette.techBlack,
        '&:hover': {
            backgroundColor: theme.palette.borderGray,
        },
    },
}));

function DialogBox({ open, onConfirm, onCancel, title, message, confirmText = 'Confirm' }) {
    const classes = useStyles();
    return (
        <Dialog data-testid="submit-dialog-box" open={open} fullWidth maxWidth="sm">
            <DialogTitle> {title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialog}>
                <Button
                    data-testid="cancel-submit-button"
                    className={classnames(classes.formButton, classes.dialogButton, classes.formButtonCancel)}
                    onClick={onCancel}
                >
                    <span>Cancel</span>
                </Button>
                <Button
                    data-testid="confirm-submit-button"
                    className={classnames(classes.formButton, classes.dialogButton)}
                    onClick={onConfirm}
                >
                    <span>{confirmText}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DialogBox.propTypes = {
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    confirmText: PropTypes.string,
};

export default DialogBox;
