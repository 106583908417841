import { createSelector } from 'reselect';
import { initialState } from './slice';

/**
 * Direct selector to the UserListPage state domain
 */

const selectOrganizationPageDomain = (state) => state.organizationPage || initialState;

/**
 * Other specific selectors
 */

const selectOrganizations = createSelector(selectOrganizationPageDomain, (substate) => substate.organizations);

const selectProviders = createSelector(selectOrganizationPageDomain, (substate) => substate.providers);

const selectOrganizationFilter = createSelector(
    selectOrganizationPageDomain,
    (substate) => substate.organizationFilter,
);
const selectOrganizationLoadState = createSelector(
    selectOrganizationPageDomain,
    (substate) => substate.organizationLoadState,
);
const selectOrganizationError = createSelector(
    selectOrganizationPageDomain,
    (substate) => substate.organizationLoadError,
);

const selectBrands = createSelector(selectOrganizationPageDomain, (substate) => substate.brands);
const selectBrandsLoadState = createSelector(selectOrganizationPageDomain, (substate) => substate.brandLoadState);
const selectBrandError = createSelector(selectOrganizationPageDomain, (substate) => substate.organizationLoadError);
const selectPageNumbers = createSelector(selectOrganizationPageDomain, (substate) => substate.pageNumbers);
const selectOrgPageNumber = createSelector(selectPageNumbers, (substate) => substate.organization);
const selectBrandPageNumber = createSelector(selectPageNumbers, (substate) => substate.brand);
const selectAccountPageNumber = createSelector(selectPageNumbers, (substate) => substate.account);

const selectAccounts = createSelector(selectOrganizationPageDomain, (substate) => substate.accounts);

const selectGetProviderLoadState = createSelector(
    selectOrganizationPageDomain,
    (substate) => substate.getProvidersLoadState,
);

const selectOrgTypes = createSelector(selectOrganizationPageDomain, (substate) => substate.orgTypes);

export {
    selectOrganizationPageDomain,
    selectOrganizations,
    selectOrganizationLoadState,
    selectOrganizationError,
    selectOrganizationFilter,
    selectBrands,
    selectBrandsLoadState,
    selectBrandError,
    selectPageNumbers,
    selectProviders,
    selectOrgPageNumber,
    selectBrandPageNumber,
    selectAccountPageNumber,
    selectGetProviderLoadState,
    selectOrgTypes,
    selectAccounts,
};
