import React from 'react';

export const ResourceOffsiteDisplay = () => (
    <>
        <br />
        <br />
        <h3>Display.Ads - Offsite Display &amp; Video</h3>
        <br />
        <p>
            Offsite Ads tell your brand story through eye-catching visuals and reaches your unique audience across the
            web.
        </p>
        <br />
        <br />
        <h3>Report Views </h3>
        <br />
        <p>
            <b>Summary Page </b>
            <ul>
                <li>
                    This page shows the daily performance of the campaign and includes line graphs of common KPIs to
                    show trends over time.
                </li>
                <li>
                    This is also where you will see total campaign recaps for the time period the report was pulled.
                </li>
                <li>
                    Most common questions can be answered from this page such as what the total ROAS is, CTR, and what
                    happened on a particular day or over a report time period.
                </li>
                <li>
                    For Offsite Video campaigns, the summary page will include video-specific metrics such as Video
                    Starts, VCR, and quartiles.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Device View </b>
            <ul>
                <li>
                    Device view shows how the campaign is performing across various devices, such as desktop or mobile.
                </li>
                <li>
                    This view is useful in understanding which devices customers are using most often to interact with
                    your products.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Publisher View </b>
            <ul>
                <li>
                    Google is the only publisher at the time, Publisher Data is at the SKU level, meaning we only
                    present the activity for the defined SKU set of the campaign.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Audience View </b>
            <ul>
                <li>
                    Audience view shows how the campaign is performing across various audiences applied to the campaign
                    targeting.
                </li>
            </ul>
        </p>
        <br />
        <p>
            <b>Product View </b>
            <ul>
                <li>Product view shows which SKUs customers are purchasing related to your campaign.</li>
                <li>
                    This view is useful in understanding which products customers ultimately purchased after viewing or
                    clicking on your campaign. This view provides attribution details for online and instore revenue by
                    SKU.
                </li>
                <li>
                    Attributes related to the Best Buy Product hierarchy, such as Brand, Department, Class and Subclass
                    are additional columns you may add to reporting using the Modify Columns feature when viewing a
                    campaign.
                </li>
            </ul>
        </p>
        <br />
        <br />
        <h3>Report Glossary </h3>
        <br />
        <p>
            <b>Impression: </b>
            Impression = a page load. An impression is counted each time a creative is downloaded in the user&lsquo;s
            device and has begun to load.
        </p>
        <br />
        <p>
            <b>Delivered Spend: </b>
            The spend attributed to the campaign as whole, the device type, or audience.
        </p>
        <br />
        <p>
            <b>Click: </b>
            When a user clicks on an ad creative.
        </p>
        <br />
        <p>
            <b>CTR: </b>
            Ratio of clicks divided by impressions to indicate click through rate.
        </p>
        <br />
        <p>
            <b>Online Revenue: </b>
            The total online revenue (ecommerce) that are attributed to a Click or View Ad event, as a result of
            matching both enterprise customer identifiers and ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>Online Units: </b>
            The total number of online units sold (ecommerce) attributed to a Click or View Ad event, as a result of
            matching both enterprise customer identifiers and ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>In Store Revenue: </b>
            The total in store revenue attributed to a Click or View Ad event, as a result of matching both enterprise
            customer identifiers and ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>In Store Units: </b>
            The total number of in store units sold that are attributed to a Click or View Ad event, as a result of
            matching both enterprise customer identifiers and ad identifiers to the Click or View Ad Event.
        </p>
        <br />
        <p>
            <b>Online ROAS: </b>
            Ratio of total online revenue (ecommerce) attributed to a Click or View Ad event divided by the spend
            attributed to the campaign as a whole, the device type, or audience.
        </p>
        <br />
        <p>
            <b>In Store ROAS: </b>
            Ratio of total in store revenue attributed to a Click or View Ad event divided by the spend attributed to
            the campaign as a whole, the device type, or audience. Available as a dollar amount or percentage.
        </p>
        <br />
        <p>
            <b>CPU: </b>
            Ratio of Ad Spend divided by Total Units sold to indicate Cost Per Unit.
        </p>
        <br />
        <p>
            <b>Video Starts: </b>
            The number of times a video has been started. This is logged once per view, such that if a person stops
            playing video and then restarts it, the restart isn&apos;t counted.
        </p>
        <br />
        <p>
            <b>Video Completion Rate: </b>
            The percentage of video views that were played to 100%.
        </p>
        <br />
        <p>
            <b>25% Complete: </b>
            The number of times a video has been played to or past 25% of its entire length.
        </p>
        <br />
        <p>
            <b>50% Complete: </b>
            The number of times a video has been played to or past 50% of its entire length.
        </p>
        <br />
        <p>
            <b>75% Complete: </b>
            The number of times a video has been played to or past 75% of its entire length.
        </p>
        <br />
        <p>
            <b>100% Complete: </b>
            The number of times a video has been played to or past 100% of its entire length.
        </p>
        <br />
        <br />
        <h3>Attribution </h3>
        <br />
        <p>
            For Offsite Display and Offsite Video Campaigns, we are attributing transactions (revenue and units) to the
            ad events such as view and clicks, via publisher ad tracking. Offsite Display and Offsite Video uses a
            multi-touch attribution methodology.
            <br />
            <br />
            Once that transaction is identified, the MyAds platform will take the transaction ID and match it to Best
            Buy transaction data, using the defined set of SKUs, to come up with a highly accurate match based on first
            party transaction data. This &#34;match&#34; will be the basis for revenue and ultimately ROAS calculations
            for these campaigns.
            <br />
            <br />
            View and click attribution are de-duplicated but are not de-duplicated across campaigns.
            <br />
            <br />
            <b>Important note about In-Store Transcation Timing: </b>
            <br />
            In-store transactiond data will <i>lag</i> online transaction data by approximately 3 days in Offsite
            Display and Offsite Video Campaign Performance Reports. This is due to the processing time to match the ad
            activities to in-store transactions through the various data systems.
            <br />
            <br />
            <b>How do we attribute sales for online and in store purchases? </b>
            <br />
            We use a first party customer identifier to track unique customers across devices on our website. Then we
            match back any sales from these customers within our 14-day attribution window for in-store.
            <br />
            Note: ROAS - by product is not possible in Offsite Display, as we cannot determine delivered spend by
            product, and same is true for engagement data (Click, Impression and Spend)
            <br />
        </p>
        <br />
        <p>
            <b>Click-through Attribution</b> - Conversions generated post-click
            <br />
            <ul>
                <li>
                    Attribution: Each time a creative is clicked on the user&lsquo;s device after which a purchase is
                    made within 14 days.
                </li>
                <li>Includes a 14-day lookback window. </li>
            </ul>
        </p>
        <br />
        <p>
            <b>View-through Attribution</b> - Conversions generated post-impression
            <br />
            <ul>
                <li>
                    Attribution: Each time a creative is downloaded on the user&lsquo;s device and has begun to load
                    after which a purchase is made within 14 days.
                </li>
                <li>Includes a 14-day lookback window.</li>
                <li>View-through attribution is inclusive of click-through attribution. </li>
            </ul>
        </p>
        <br />
        <br />
        <h3>Reporting FAQs </h3>
        <br />
        <p>
            <b>For what timeframe can I see reporting? </b>
            <br />
            Offsite Display and Video data is available from February, 2022 and onward.
        </p>
        <br />
        <p>
            <b>What metrics align to what goals? </b>
            <br />
            <ul>
                <li>Awareness = Impressions</li>
                <li>Consideration = Clicks, CTR</li>
                <li>Conversion = Revenue, Units, ROAS, CPU</li>
            </ul>
        </p>
        <br />
        <p>
            <b>What CTR or ROAS can we expect to see from our campaign? </b>
            <br />
            Each campaign&lsquo;s performance can vary due to a variety of factors. Here are some things to consider:
            <br />
            <br />
            <ol>
                <li>
                    <b>Dates the campaign is live</b>
                    <br />
                    Secondary holiday timeframes (Memorial Day, Labor Day, etc.), or product launch dates, for example,
                    may impact campaign performance.
                </li>
                <br />
                <li>
                    <b>Reporting SKUs selected</b>
                    <br />
                    Including the most SKUs possible will typically result in higher ROAS. It is always recommended to
                    include the most amount of SKUs possible.
                </li>
                <br />
                <li>
                    <b>ASP of reporting SKUs</b>
                    <br />
                    Higher priced items generally result in higher ROAS as the SKU price is relative to delivered spend
                    (and the same correlation will be seen with lower priced items).
                </li>
                <br />
                <li>
                    <b>Size of potential purchaser audience</b>
                    <br />
                    Accessory SKUs typically require ownership of specific hardware to use them which may result in
                    lower ROAS and CTR. For example, you need a certain DSLR camera to use a certain lens, or an iPhone
                    13 to use an iPhone 13 case.
                </li>
                <br />
            </ol>
        </p>
        <br />
        <br />
    </>
);
