import React from 'react';
import PropTypes from 'prop-types';
import FlyoutModal from 'components/FlyoutModal';
import { FlyoutAction } from '../../../shared/constants';
import SendToKoddiFlyout from '../OrgFlyouts/SendToKoddi/SendToKoddiFlyout';
import ShareCampaignFlyout from '../OrgFlyouts/ShareCampaignFlyout';
import AddOrgFlyout from '../OrgFlyouts/AddOrgFlyout';
import AddBrandFlyout from '../OrgFlyouts/AddBrandFlyout';
import AddAccountFlyout from '../OrgFlyouts/AddAccountFlyout';
import EditOrgFlyout from '../OrgFlyouts/EditOrgFlyout';
import EditAccountFlyout from '../OrgFlyouts/EditAccountFlyout';
import EditBrandFlyout from '../OrgFlyouts/EditBrandFlyout';
import MoveAccountFlyout from '../OrgFlyouts/MoveAccountFlyout';

export function OrgFlyoutContainer({
    organizations,
    orgLoadState,
    brands,
    brandsLoading,
    accounts,
    flyoutStatus,
    selectedItem,
    onCloseOrgFlyoutModal,
}) {
    let title = '';
    let flyoutContent = null;
    let isOpen = true;

    switch (flyoutStatus) {
        case FlyoutAction.SEND_TO_KODDI:
            title = 'Send To Koddi';
            flyoutContent = (
                <SendToKoddiFlyout
                    closeFlyout={onCloseOrgFlyoutModal}
                    brands={brands}
                    loading={brandsLoading}
                    organization={selectedItem}
                />
            );
            break;
        case FlyoutAction.SHARE:
            title = 'Share Campaigns';
            flyoutContent = (
                <ShareCampaignFlyout
                    organization={selectedItem}
                    orgLoadState={orgLoadState}
                    orgOptions={organizations}
                    brands={brands}
                    closeFlyout={onCloseOrgFlyoutModal}
                />
            );
            break;
        case FlyoutAction.ADD_ORGANIZATION:
            title = 'Create Organization';
            flyoutContent = (
                <AddOrgFlyout
                    organization={selectedItem}
                    organizations={organizations}
                    orgLoadState={orgLoadState}
                    orgOptions={organizations}
                    closeFlyout={onCloseOrgFlyoutModal}
                />
            );
            break;
        case FlyoutAction.ADD_BRAND:
            title = 'Add Brand';
            flyoutContent = <AddBrandFlyout closeFlyout={onCloseOrgFlyoutModal} brands={brands} />;
            break;
        case FlyoutAction.EDIT_BRAND:
            title = 'Edit Brand';
            flyoutContent = (
                <EditBrandFlyout closeFlyout={onCloseOrgFlyoutModal} brand={selectedItem} brands={brands} />
            );
            break;
        case FlyoutAction.ADD_ACCOUNT:
            title = 'Create Account';
            flyoutContent = (
                <AddAccountFlyout
                    organizations={organizations}
                    closeFlyout={onCloseOrgFlyoutModal}
                    brands={brands}
                    accounts={accounts}
                />
            );
            break;
        case FlyoutAction.MOVE_ACCOUNT:
            title = 'Move Account';
            flyoutContent = (
                <MoveAccountFlyout
                    organizations={organizations}
                    brands={brands}
                    account={selectedItem}
                    closeFlyout={onCloseOrgFlyoutModal}
                />
            );
            break;
        case FlyoutAction.EDIT_ORGANIZATION:
            title = 'Edit Organization';
            flyoutContent = (
                <EditOrgFlyout
                    organization={selectedItem}
                    organizations={organizations}
                    orgLoadState={orgLoadState}
                    closeFlyout={onCloseOrgFlyoutModal}
                />
            );
            break;
        case FlyoutAction.EDIT_ACCOUNT:
            title = 'Edit Account';
            flyoutContent = (
                <EditAccountFlyout
                    closeFlyout={onCloseOrgFlyoutModal}
                    brands={brands}
                    account={selectedItem}
                    accounts={accounts}
                />
            );
            break;
        default:
            isOpen = false;
            break;
    }

    return (
        <FlyoutModal title={title} open={isOpen} onClose={onCloseOrgFlyoutModal}>
            {flyoutContent}
        </FlyoutModal>
    );
}

OrgFlyoutContainer.propTypes = {
    flyoutStatus: PropTypes.string,
    selectedItem: PropTypes.object,
    onCloseOrgFlyoutModal: PropTypes.func,
    brands: PropTypes.array,
    brandsLoading: PropTypes.bool,
    accounts: PropTypes.array,
    organizations: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            userCount: PropTypes.number.isRequired,
            campaignCount: PropTypes.number.isRequired,
            brands: PropTypes.array.isRequired,
            orgId: PropTypes.string.isRequired,
        }),
    ).isRequired,
    orgLoadState: PropTypes.string,
};
