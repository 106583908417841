import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import classnames from 'classnames';
import { VALIDATE_ON_MODIFIED, VALIDATE_ON_SUBMIT } from 'utils/constants';
import Labels from './Labels';

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 14,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        background: '#FFFFFF',
        borderRadius: '4px',
        marginBottom: '1em',
    },
    successStyle: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `2px solid ${theme.palette.successGreen} !important`,
                color: theme.palette.successGreen,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
            },
            '& svg': {
                fill: theme.palette.successGreen,
            },
        },
    },
    fieldErrorStyle: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: `2px solid ${theme.palette.valueRed} !important`,
                color: theme.palette.valueRed,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 500,
            },
            '& svg': {
                fill: theme.palette.valueRed,
            },
        },
        '&.MuiFormHelperText-contained': {
            margin: '8px 0px 0',
        },
    },
}));

// This component is specifically an abstracted component to work with React Final Form to reduce some redundancy
const Input = ({
    meta: { touched, error, modified, submitFailed } = {
        touched: false,
        error: undefined,
    },
    input,
    inputLabel,
    inputSublabel,
    labelSpaceStyle,
    reset,
    inputProps,
    validateOn,
    ...props
}) => {
    const classes = useStyles();

    const getValidateState = () => {
        switch (validateOn) {
            case VALIDATE_ON_SUBMIT:
                return submitFailed;
            case VALIDATE_ON_MODIFIED:
                return modified;
            default:
                return touched;
        }
    };

    const shouldValidate = getValidateState();

    return (
        <div>
            <Labels
                inputLabel={inputLabel}
                inputSublabel={inputSublabel}
                labelSpaceStyle={labelSpaceStyle}
                htmlFor={inputProps?.id}
            />
            <TextField
                inputProps={{
                    ...inputProps,
                }}
                {...input}
                {...props}
                fullWidth
                className={classnames(
                    classes.inputStyle,
                    shouldValidate && !error && classes.successStyle,
                    shouldValidate && error && classes.fieldErrorStyle,
                )}
                error={!!(shouldValidate && error)}
                helperText={shouldValidate && error}
            />
        </div>
    );
};

Input.propTypes = {
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        valid: PropTypes.bool,
        modified: PropTypes.bool,
    }),
    inputProps: PropTypes.shape({
        'data-testid': PropTypes.string.isRequired,
        id: PropTypes.string,
    }),
    input: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        checked: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        type: PropTypes.string,
    }),
    inputLabel: PropTypes.string,
    inputSublabel: PropTypes.string,
    labelSpaceStyle: PropTypes.bool,
    reset: PropTypes.func,
    validateOn: PropTypes.string,
};

export default Input;
