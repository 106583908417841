import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { loadOrgTypesAction, updateOrgAction } from 'containers/OrganizationPage/slice';
import { selectOrgTypes } from 'containers/OrganizationPage/selectors';
import { createStructuredSelector } from 'reselect';
import AddAndEditOrgForm from '../Shared/AddAndEditOrgForm';
import DialogBox from '../../../../components/DialogBox';

export function EditOrgFlyout({ getOrgTypes, orgTypes, updateOrg, closeFlyout, organization, organizations }) {
    const [submitValues, setSubmitValues] = useState(null);

    useEffect(() => {
        getOrgTypes();
    }, [getOrgTypes]);

    const onSubmit = (values) => {
        setSubmitValues(values);
    };

    const initialValues = useMemo(
        () => ({
            name: organization.name || '',
            type: organization.type || '',
            orgId: organization.orgId,
        }),
        [organization],
    );

    const onSubmitEditOrgCancel = () => {
        setSubmitValues(null);
    };

    const onEditOrgSubmitProceed = () => {
        if (submitValues) {
            const newOrgValues = {
                name: submitValues.name,
                type: submitValues.type,
                orgId: organization.orgId,
            };
            updateOrg(newOrgValues);
            closeFlyout();
        }
    };

    return (
        <>
            <AddAndEditOrgForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitDisabled={submitValues !== null}
                orgTypes={orgTypes}
                organizations={organizations}
                isEdit
            />
            <DialogBox
                open={submitValues !== null}
                onConfirm={onEditOrgSubmitProceed}
                onCancel={onSubmitEditOrgCancel}
                title="Update Organization"
                message="Are you sure you want to update the Organization?"
                confirmText="Update Organization"
            />
        </>
    );
}

EditOrgFlyout.propTypes = {
    getOrgTypes: PropTypes.func,
    orgTypes: PropTypes.array,
    updateOrg: PropTypes.func,
    closeFlyout: PropTypes.func,
    organization: PropTypes.object,
    organizations: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    orgTypes: selectOrgTypes,
});

export function mapDispatchToProps(dispatch) {
    return {
        getOrgTypes: () => dispatch(loadOrgTypesAction()),
        updateOrg: (values) => dispatch(updateOrgAction(values)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditOrgFlyout);
