/**
 *
 * OrgActionButton
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Fab, SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FlyoutAction } from 'shared/constants';
import { OrgPageTabs } from '../../containers/OrganizationPage/constants';

const useStyles = makeStyles(() => ({
    fabButton: {
        backgroundColor: '#0146BE',
        color: '#ffffff',
        boxShadow: 'none',
        fontSize: '12px',
        textTransform: 'capitalize',
        '& .MuiSvgIcon-root': {
            margin: '0 5px 0 0',
            fontSize: 'medium',
        },
    },
}));

const addIcon = () => (
    <SvgIcon sx={{ margin: '0 20px', fontSize: 'small' }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
            color="#ffffff"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
    </SvgIcon>
);

const OrgActionButton = ({ tab, onClick }) => {
    const classes = useStyles();

    let title = '';
    let action = null;

    switch (tab) {
        case OrgPageTabs.ORGANIZATIONS.key:
            title = 'Add Organization';
            action = () => onClick(FlyoutAction.ADD_ORGANIZATION);
            break;
        case OrgPageTabs.BRANDS.key:
            title = 'Add Brand';
            action = () => onClick(FlyoutAction.ADD_BRAND);
            break;
        case OrgPageTabs.ACCOUNTS.key:
            title = 'Add Account';
            action = () => onClick(FlyoutAction.ADD_ACCOUNT);
            break;
        default:
            title = 'Add';
            action = () => {};
            break;
    }

    return (
        <div>
            <Fab variant="extended" color="primary" size="medium" className={classes.fabButton} onClick={action}>
                {addIcon()}
                {title}
            </Fab>
        </div>
    );
};

OrgActionButton.propTypes = {
    tab: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default OrgActionButton;
