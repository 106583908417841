/**
 *
 * Tabs
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MaterialTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const tabWidth = '175px';

const useStyles = makeStyles((theme) => ({
    tabs: {
        borderRadius: 2,
    },
    tab: {
        width: tabWidth,
        height: '14px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: ' normal',
        textAlign: 'center',
        opacity: 1,
        color: theme.palette.lightGray,
        '&:hover': {
            boxShadow: `inset 0 -4px 0 0${theme.palette.humanBlue}`,
        },
        '&.Mui-selected': {
            width: tabWidth,
            height: '30px',
            color: theme.palette.humanBlue,
        },
        '& .MuiTab-wrapper': {
            padding: '6px 12px 7px 12px',
        },
    },
    indicator: {
        backgroundColor: theme.palette.humanBlue,
        height: '4px',
    },
}));

const Tabs = ({ onChange, value, disabled, tabHeaders }) => {
    const classes = useStyles();

    return (
        <MaterialTabs
            className={classes.tabs}
            variant="standard"
            onChange={onChange}
            value={value}
            classes={{
                indicator: classes.indicator,
            }}
            id="tabs"
            aria-label="tabs"
        >
            {Object.keys(tabHeaders).map((tab) => (
                <Tab
                    key={tabHeaders[tab].key}
                    value={tab}
                    name={tabHeaders[tab].key}
                    label={tabHeaders[tab].label}
                    className={classes.tab}
                    disabled={disabled}
                    aria-label={`${tabHeaders[tab].label} tab`}
                />
            ))}
        </MaterialTabs>
    );
};

Tabs.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    tabHeaders: PropTypes.objectOf(
        PropTypes.shape({
            label: PropTypes.string,
            key: PropTypes.string,
        }),
    ),
};

Tabs.defaultProps = {
    disabled: false,
    tabHeaders: [],
};

export default memo(Tabs);
