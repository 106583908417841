import { Paper, FormControlLabel, Switch, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateFeatureFlag } from 'utils/featureFlags/slice';
import useFeatureFlags from 'utils/featureFlags/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: 'grid',
        justifyItems: 'start',
        alignItems: 'center',
        padding: '3rem',
    },
    formContainer: {
        padding: '1rem',
        minWidth: '30rem',
        '& h2': {
            textAlign: 'center',
            fontFamily: theme.typography.fontFamily,
        },
    },
}));

function FeatureFlagControl() {
    const styles = useStyles();
    const featureFlags = useFeatureFlags();
    const dispatch = useDispatch();

    const createOnChange = (flagName) => (event, value) =>
        dispatch(
            updateFeatureFlag({
                flagName,
                isActive: value,
            }),
        );

    return (
        <div className={styles.rootContainer}>
            <Paper className={styles.formContainer}>
                <h2>Toggle Feature Flags</h2>
                {Object.entries(featureFlags).map(([flagName, isActive]) => (
                    <div key={flagName}>
                        <FormControlLabel
                            control={<Switch checked={isActive} onChange={createOnChange(flagName)} />}
                            label={flagName}
                        />
                    </div>
                ))}
            </Paper>
        </div>
    );
}

export default FeatureFlagControl;
