import { createSlice } from '@reduxjs/toolkit';
import env from 'config/env';

const initialState = {
    showPendingCampaigns: env.BBY_ENV === 'development' || env.BBY_ENV === 'local',
    showDevPage: env.BBY_ENV === 'local',
    showIdamPhone: false,
    showUserManagement: true,
    sendToKoddi: true,
    showPaidSearchPtc: env.BBY_ENV !== 'production',
    showOrgManagement: env.BBY_ENV !== 'production',
    ...env.FEATURE_FLAGS,
};

/* eslint-disable no-param-reassign */
const featureFlagSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        updateFeatureFlag(state, action) {
            const { flagName, isActive } = action.payload;
            state[flagName] = isActive;
        },
    },
});

export const { updateFeatureFlag } = featureFlagSlice.actions;
export default featureFlagSlice.reducer;
