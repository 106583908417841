/**
 *
 * ListSearch
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, IconButton, TextField, Tooltip } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    bottom: {
        height: '0',
        overflow: 'hidden',
        transition: 'height .25s, opacity .25s',
        display: 'flex',
        justifyContent: 'flex-end',
        opacity: 0,
        marginTop: '10px',

        '&[data-focus="true"]': {
            height: '42px',
            opacity: 1,
        },
    },
    searchField: {
        width: '200px',
        zIndex: 3,
        transition: '.25s width',

        '&[data-focus="true"]': {
            width: '400px',
        },
    },
    searchInput: {
        fontSize: '14px',
        paddingRight: '37px',
        background: theme.palette.standardWhite,
    },
    clearButton: {
        marginLeft: '-35px',
        marginRight: '5px',
    },
    searchSelectBox: {
        marginRight: theme.spacing(-10),
        transition: '.25s margin-right',
        visibility: 'hidden',

        '&[data-focus="true"]': {
            marginRight: theme.spacing(2),
            visibility: 'visible',
        },
    },
    searchSelect: {
        fontSize: '14px',
        background: theme.palette.standardWhite,
        zIndex: 2,
    },
    searchSelectItem: {
        fontSize: '14px',
    },
    searchLabel: {
        zIndex: 0,

        '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 12px) scale(1)',
        },

        '&[data-focus="true"]': {
            transform: 'translate(0, -20px) scale(0.75) !important',
        },
    },
    searchCheckboxLabel: {
        padding: 0,

        '&::before': {
            border: 'none',
        },

        '&::after': {
            border: 'none',
        },

        '& input': {
            select: 'none',
            cursor: 'default',
        },
    },
    searchCheckbox: {
        '& .MuiFormControlLabel-label': {
            fontSize: '14px',
        },
    },
}));

export const ListSearch = ({ onSearch, includes, keyword, hideCheckboxes, ...rest }) => {
    const classes = useStyles();

    const [searchKeyword, setSearchKeyword] = useState(keyword || '');
    const [searchFocus, setSearchFocus] = useState(false);

    const [searchIncludes, setSearchIncludes] = useState(
        (includes || []).map((field) => {
            if (field.checked === undefined) {
                return { ...field, checked: true };
            }
            return field;
        }),
    );

    const onClear = () => {
        setSearchKeyword('');
        setSearchFocus(false);
    };

    const onIncludesChange = (checked, index) => {
        setSearchFocus(true);
        setSearchIncludes((preSearchIncludes) => {
            const newSearchIncludes = [...preSearchIncludes];
            newSearchIncludes[index] = { ...newSearchIncludes[index], checked };

            onSearch(searchKeyword, newSearchIncludes);
            return newSearchIncludes;
        });
    };

    const onKeywordChange = (value) => {
        setSearchKeyword(value);
        onSearch(value, searchIncludes);
    };

    return (
        <div
            className={classNames(classes.root, rest.className)}
            onFocus={() => setSearchFocus(true)}
            onBlur={() => setSearchFocus(searchKeyword.length !== 0)}
        >
            <div className={classes.top}>
                <TextField
                    className={classes.searchField}
                    data-focus={searchFocus}
                    value={searchKeyword}
                    placeholder="Search..."
                    size="small"
                    variant="outlined"
                    inputProps={{
                        className: classes.searchInput,
                        'aria-label': 'search-input',
                    }}
                    onChange={(event) => onKeywordChange(event.target.value)}
                />
                {searchKeyword.length > 0 && (
                    <Tooltip title="Clear search" placement="top-end">
                        <IconButton className={classes.clearButton} aria-label="clear" size="small" onClick={onClear}>
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {!hideCheckboxes && (
                <div className={classes.bottom} data-focus={searchFocus}>
                    {searchIncludes.map((include, index) => (
                        <FormControlLabel
                            key={include.name + include.label}
                            className={classes.searchCheckbox}
                            control={
                                <Checkbox
                                    checked={include.checked}
                                    color="primary"
                                    name={include.name}
                                    aria-labelledby={include.name}
                                />
                            }
                            label={include.label}
                            labelPlacement="start"
                            onChange={(event) => onIncludesChange(event.target.checked, index)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

ListSearch.propTypes = {
    onSearch: PropTypes.func.isRequired,
    includes: PropTypes.array,
    keyword: PropTypes.string,
    hideCheckboxes: PropTypes.bool,
};

export default ListSearch;
