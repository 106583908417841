/* eslint-disable prefer-destructuring */
import { createSelector } from 'reselect';

import { makeSelectCampaigns, makeSelectCampaignsHashmap } from 'containers/App/selectors';

import { initialState } from './reducer';
import { getFurthestStartDate, getLatestEndDate, getCampaignsFromIds } from './utils';
import {
    BUILD_ONSITE_DISPLAY_GROUP_NAME,
    BUILD_SPONSORED_RSX_GROUP_NAME,
    BUILD_SPONSORED_RMP_GROUP_NAME,
} from './constants';
/**
 * Direct selector to the stackedCampaignsPage state domain
 */

const selectStackedCampaignsPageDomain = (state) => state.stackedCampaignsPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by StackedCampaignsPage
 */

const makeSelectStackedCampaignsPage = () => createSelector(selectStackedCampaignsPageDomain, (substate) => substate);

const makeSelectCampaignGroups = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => substate.campaignGroups);

const makeSelectBuildReports = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => substate.buildReports);

const makeSelectBuildDisplayCampaigns = () =>
    createSelector(makeSelectBuildDisplayGroup(), makeSelectCampaignsHashmap(), (displayReportGroup, campaignHash) => {
        const campaignIds = displayReportGroup.campaignIds || [];
        return getCampaignsFromIds(campaignIds, campaignHash);
    });

const makeSelectBuildDisplayGroup = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => {
        const displayReportGroup =
            substate.campaignGroups.find(
                (campaignGroup) => campaignGroup.groupName === BUILD_ONSITE_DISPLAY_GROUP_NAME,
            ) || {};

        return displayReportGroup;
    });

const makeSelectBuildSponsoredCampaigns = () =>
    createSelector(
        makeSelectBuildSponsoredGroup(),
        makeSelectCampaignsHashmap(),
        (sponsoredReportGroup, campaignHash) => {
            const campaignIds = sponsoredReportGroup.campaignIds || [];
            return getCampaignsFromIds(campaignIds, campaignHash);
        },
    );

const makeSelectBuildSponsoredGroup = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => {
        const sponsoredReportGroup =
            substate.campaignGroups.find(
                (campaignGroup) => campaignGroup.groupName === BUILD_SPONSORED_RSX_GROUP_NAME,
            ) || {};

        return sponsoredReportGroup;
    });

const makeSelectBuildSponsoredRMPCampaigns = () =>
    createSelector(
        makeSelectBuildSponsoredRMPGroup(),
        makeSelectCampaignsHashmap(),
        (sponsoredReportGroup, campaignHash) => {
            const campaignIds = sponsoredReportGroup.campaignIds || [];
            return getCampaignsFromIds(campaignIds, campaignHash);
        },
    );

const makeSelectBuildSponsoredRMPGroup = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => {
        const sponsoredReportGroup =
            substate.campaignGroups.find(
                (campaignGroup) => campaignGroup.groupName === BUILD_SPONSORED_RMP_GROUP_NAME,
            ) || {};

        return sponsoredReportGroup;
    });

const makeSelectMyReports = () =>
    createSelector(selectStackedCampaignsPageDomain, makeSelectCampaigns(), (substate, campaigns) =>
        substate.campaignGroups
            .map((campaignGroup) => ({
                ...campaignGroup,
                shared: campaignGroup.shared,
                name: campaignGroup.groupName,
                type: campaignGroup.groupType,
                size: campaignGroup.campaignIds ? campaignGroup.campaignIds.length : 0,
                startDateTime: getFurthestStartDate(campaigns, campaignGroup.campaignIds),
                endDateTime: getLatestEndDate(campaigns, campaignGroup.campaignIds),
            }))
            .filter(
                (campaignGroup) =>
                    campaignGroup.groupName !== BUILD_ONSITE_DISPLAY_GROUP_NAME &&
                    campaignGroup.groupName !== BUILD_SPONSORED_RSX_GROUP_NAME &&
                    campaignGroup.groupName !== BUILD_SPONSORED_RMP_GROUP_NAME,
            ),
    );

const makeSelectSharedReports = () =>
    createSelector(selectStackedCampaignsPageDomain, makeSelectCampaigns(), (substate, campaigns) =>
        substate.sharedReports
            .map((campaignGroup) => ({
                ...campaignGroup,
                user: campaignGroup.user,
                name: campaignGroup.groupName,
                type: campaignGroup.groupType,
                size: campaignGroup.campaignIds.length,
                startDateTime: getFurthestStartDate(campaigns, campaignGroup.campaignIds),
                endDateTime: getLatestEndDate(campaigns, campaignGroup.campaignIds),
            }))
            .filter((campaignGroup) => campaignGroup.shared)
            .reverse(),
    );

const makeSelectCampaignGroupsLoading = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => substate.campaignGroupsLoading);

const makeSelectCampaignGroupsError = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => substate.campaignGroupsError);

const makeSelectCampaignGroupsDone = () =>
    createSelector(selectStackedCampaignsPageDomain, (substate) => substate.campaignGroupsDone);

export default makeSelectStackedCampaignsPage;
export {
    selectStackedCampaignsPageDomain,
    makeSelectStackedCampaignsPage,
    makeSelectCampaignGroups,
    makeSelectBuildReports,
    makeSelectBuildDisplayCampaigns,
    makeSelectBuildDisplayGroup,
    makeSelectBuildSponsoredCampaigns,
    makeSelectBuildSponsoredGroup,
    makeSelectBuildSponsoredRMPCampaigns,
    makeSelectBuildSponsoredRMPGroup,
    makeSelectMyReports,
    makeSelectSharedReports,
    makeSelectCampaignGroupsLoading,
    makeSelectCampaignGroupsError,
    makeSelectCampaignGroupsDone,
};
