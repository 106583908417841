import * as vehicles from '../../vehicleConstants';

export const paidSearchSkuColumns = {
    campaign: [
        // Campaign Type
        'campaign_type',
        // Publisher  (Google, Bing, or Yahoo)
        'publisher',
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Spend  ($, to cents)
        'spend',
        // Online Revenue ($, to cents)
        'revenue_online',
        // In Store Revenue ($, to cents)
        'revenue_instore',
        // Total Revenue ($, to cents)
        'revenue_total',
        // Online ROAS ($, to cents)
        'roas_online',
        // In Store ROAS ($, to cents)
        'roas_instore',
        // Total ROAS ($, to cents)
        'roas_total',
        // Online units, number
        'units_online',
        // In Store Units, number
        'units_instore',
        // Total Units, number
        'units_total',
        // Total Cost Per Unit (CPU)
        'cpu_total',
    ],
    query: [
        // Search Query
        'query',
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Spend  ($, to cents)
        'spend',
    ],
    device: [
        // Device (Desktop, Mobile, or Tablet)
        'device',
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Delivered Spend  ($, to cents)
        'spend',
        // Online Revenue ($, to cents)
        'revenue_online_device',
        // In Store Revenue ($, to cents)
        'revenue_instore_device',
        // Total Revenue ($, to cents)
        'revenue_total_device',
        // Online ROAS ($, to cents)
        'roas_online_device',
        // In Store ROAS ($, to cents)
        'roas_instore_device',
        // Total ROAS ($, to cents)
        'roas_total_device',
        // Online units, number
        'units_online_device',
        // In Store Units, number
        'units_instore_device',
        // Total Units, number
        'units_total_device',
    ],
    product: [
        'sku_id',
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        // Online units, number
        'units_online',
        // In Store Units, number
        'units_instore',
        // Total Units, number
        'units_total',
    ],
};

export const defaultColumns = {
    campaign: [
        // Campaign Type
        'campaign_type',
        // Publisher  (Google, Bing, or Yahoo)
        'publisher',
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Spend  ($, to cents)
        'spend',
        // Online Revenue ($, to cents)
        'revenue_online',
        // In Store Revenue ($, to cents)
        'revenue_instore',
        // Total Revenue ($, to cents)
        'revenue_total',
        // Online ROAS ($, to cents)
        'roas_online',
        // In Store ROAS ($, to cents)
        'roas_instore',
        // Total ROAS ($, to cents)
        'roas_total',
        // Online units, number
        'units_online',
        // In Store Units, number
        'units_instore',
        // Total Units, number
        'units_total',
    ],
    query: [
        // Search Query
        'query',
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Spend  ($, to cents)
        'spend',
    ],
    device: [
        // Device (Desktop, Mobile, or Tablet)
        'device',
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Delivered Spend  ($, to cents)
        'spend',
        // Online Revenue ($, to cents)
        'revenue_online_device',
        // In Store Revenue ($, to cents)
        'revenue_instore_device',
        // Total Revenue ($, to cents)
        'revenue_total_device',
        // Online ROAS ($, to cents)
        'roas_online_device',
        // In Store ROAS ($, to cents)
        'roas_instore_device',
        // Total ROAS ($, to cents)
        'roas_total_device',
        // Online units, number
        'units_online_device',
        // In Store Units, number
        'units_instore_device',
        // Total Units, number
        'units_total_device',
    ],
    product: [
        'sku_id',
        'sku_description',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        // Online units, number
        'units_online',
        // In Store Units, number
        'units_instore',
        // Total Units, number
        'units_total',
    ],
};

export const modifyColumns = {
    campaign: [
        // Campaign Type
        'campaign_type',
        // Publisher  (Google, Bing, or Yahoo)
        'publisher',
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Spend  ($, to cents)
        'spend',
        // Online Revenue ($, to cents)
        'revenue_online',
        // In Store Revenue ($, to cents)
        'revenue_instore',
        // Total Revenue ($, to cents)
        'revenue_total',
        // Online ROAS ($, to cents)
        'roas_online',
        // In Store ROAS ($, to cents)
        'roas_instore',
        // Total ROAS ($, to cents)
        'roas_total',
        // Online units, number
        'units_online',
        // In Store Units, number
        'units_instore',
        // Total Units, number
        'units_total',
        // Total Cost Per Unit (CPU)
        'cpu_total',
    ],
    query: [
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Spend  ($, to cents)
        'spend',
    ],
    device: [
        // Impressions  (whole number)
        'impressions',
        // Clicks (whole number)
        'clicks',
        // CTR  (%, to hundredths)
        'ctr',
        // CPC ($, to cents)
        'cpc',
        // Spend  ($, to cents)
        'spend',
        // Online Revenue ($, to cents)
        'revenue_online_device',
        // In Store Revenue ($, to cents)
        'revenue_instore_device',
        // Total Revenue ($, to cents)
        'revenue_total_device',
        // Online ROAS ($, to cents)
        'roas_online_device',
        // In Store ROAS ($, to cents)
        'roas_instore_device',
        // Total ROAS ($, to cents)
        'roas_total_device',
        // Online units, number
        'units_online_device',
        // In Store Units, number
        'units_instore_device',
        // Total Units, number
        'units_total_device',
    ],
    product: [
        'sku_description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'revenue_online',
        'revenue_instore',
        'revenue_total',
        // Online units, number
        'units_online',
        // In Store Units, number
        'units_instore',
        // Total Units, number
        'units_total',
    ],
};

export const columnMap = {
    campaign_name: {
        label: 'Campaign Name',
        type: 'description',
        field: 'campaign_name',
        sortable: true,
        lineBreak: false,
    },
    campaign_type: {
        label: 'Campaign Type',
        type: 'description',
        field: 'campaign_type',
        sortable: true,
        lineBreak: false,
    },
    publisher: {
        label: 'Publisher',
        type: 'description',
        field: 'publisher',
        sortable: true,
        lineBreak: false,
    },
    impressions: {
        label: 'Impressions',
        type: 'number',
        field: 'impressions',
        sortable: true,
        lineBreak: false,
    },
    clicks: {
        label: 'Clicks',
        type: 'number',
        field: 'clicks',
        sortable: true,
        lineBreak: false,
    },
    ctr: {
        label: 'CTR',
        type: 'percent_decimal',
        field: 'ctr',
        sortable: true,
        lineBreak: false,
    },
    cpc: {
        label: 'CPC',
        type: 'currency',
        field: 'cpc',
        sortable: true,
        lineBreak: false,
    },
    orders_online: {
        label: 'Online Orders',
        type: 'number',
        field: 'orders_online',
        sortable: true,
        lineBreak: false,
    },
    orders_instore: {
        label: 'In Store Orders',
        type: 'number',
        field: 'orders_instore',
        sortable: true,
        lineBreak: false,
    },
    orders_total: {
        label: 'Total Orders',
        type: 'number',
        field: 'orders_total',
        sortable: true,
        lineBreak: false,
    },
    conversion_rate_online: {
        label: 'Online Conversion Rate',
        type: 'percent_decimal',
        field: 'conversion_rate_online',
        sortable: true,
        lineBreak: false,
    },
    conversion_rate_instore: {
        label: 'In Store Conversion Rate',
        type: 'percent_decimal',
        field: 'conversion_rate_instore',
        sortable: true,
        lineBreak: false,
    },
    conversion_rate_total: {
        label: 'Total Conversion Rate',
        type: 'percent_decimal',
        field: 'conversion_rate_total',
        sortable: true,
        lineBreak: false,
    },
    aov_online: {
        label: 'Online AOV',
        type: 'currency',
        field: 'aov_online',
        sortable: true,
        lineBreak: false,
    },
    aov_instore: {
        label: 'In Store AOV',
        type: 'currency',
        field: 'aov_instore',
        sortable: true,
        lineBreak: false,
    },
    aov_total: {
        label: 'Total AOV',
        type: 'currency',
        field: 'aov_total',
        sortable: true,
        lineBreak: false,
    },
    basket_revenue_online: {
        label: 'Online Basket Revenue',
        type: 'currency',
        field: 'basket_revenue_online',
        sortable: true,
        lineBreak: false,
    },
    basket_revenue_instore: {
        label: 'In Store Basket Revenue',
        type: 'currency',
        field: 'basket_revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    basket_revenue_total: {
        label: 'Total Basket Revenue',
        type: 'currency',
        field: 'basket_revenue_total',
        sortable: true,
        lineBreak: false,
    },
    basket_roas_online: {
        label: 'Online Basket ROAS',
        type: 'currency',
        field: 'basket_roas_online',
        sortable: true,
        lineBreak: false,
    },
    basket_roas_instore: {
        label: 'In Store Basket ROAS',
        type: 'currency',
        field: 'basket_roas_instore',
        sortable: true,
        lineBreak: false,
    },
    basket_roas_total: {
        label: 'Total Basket ROAS',
        type: 'currency',
        field: 'basket_roas_total',
        sortable: true,
        lineBreak: false,
    },
    roas_online: {
        label: 'Online ROAS',
        type: 'currency',
        field: 'roas_online',
        sortable: true,
        lineBreak: false,
    },
    roas_instore: {
        label: 'In Store ROAS',
        type: 'currency',
        field: 'roas_instore',
        sortable: true,
        lineBreak: false,
    },
    roas_total: {
        label: 'Total ROAS',
        type: 'currency',
        field: 'roas_total',
        sortable: true,
        lineBreak: false,
    },
    units_online: {
        label: 'Online Units',
        type: 'decimal',
        field: 'units_online',
        sortable: true,
        lineBreak: false,
    },
    units_instore: {
        label: 'In Store Units',
        type: 'decimal',
        field: 'units_instore',
        sortable: true,
        lineBreak: false,
    },
    units_total: {
        label: 'Total Units',
        type: 'decimal',
        field: 'units_total',
        sortable: true,
        lineBreak: false,
    },
    cpu_total: {
        label: 'Total CPU',
        type: vehicles.COL_TYPE_CURRENCY,
        field: 'cpu',
        sortable: true,
        lineBreak: false,
    },
    revenue_total: {
        label: 'Total Revenue',
        type: 'currency',
        field: 'revenue_total',
        sortable: true,
        lineBreak: false,
    },
    revenue_online: {
        label: 'Online Revenue',
        type: 'currency',
        field: 'revenue_online',
        sortable: true,
        lineBreak: false,
    },
    revenue_instore: {
        label: 'In Store Revenue',
        type: 'currency',
        field: 'revenue_instore',
        sortable: true,
        lineBreak: false,
    },
    query: {
        label: 'Search Query',
        type: 'description',
        field: 'query',
        sortable: true,
        lineBreak: false,
    },
    device: {
        label: 'Device',
        type: 'description',
        field: 'device',
        sortable: true,
        lineBreak: false,
    },
    spend: {
        label: 'Delivered Spend',
        type: 'currency',
        field: 'spend',
        sortable: true,
        lineBreak: false,
    },
    sku_id: {
        label: 'SKU',
        type: 'string',
        field: 'sku_id',
        sortable: true,
        lineBreak: false,
    },
    sku_description: {
        label: 'Description',
        type: 'description',
        field: 'sku_description',
        sortable: true,
        lineBreak: false,
    },
    brand: {
        label: 'Brand',
        type: 'description',
        field: 'brand',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_id: {
        label: 'Department ID',
        type: 'description',
        field: 'dept_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_name: {
        label: 'Department Name',
        type: 'description',
        field: 'dept_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_id: {
        label: 'Class ID',
        type: 'description',
        field: 'class_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_name: {
        label: 'Class Name',
        type: 'description',
        field: 'class_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_id: {
        label: 'SubClass ID',
        type: 'description',
        field: 'subclass_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_name: {
        label: 'SubClass Name',
        type: 'description',
        field: 'subclass_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    roas_online_device: {
        label: 'Online ROAS',
        type: 'currency',
        field: 'roas_online',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    roas_instore_device: {
        label: 'In Store ROAS',
        type: 'currency',
        field: 'roas_instore',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    roas_total_device: {
        label: 'Total ROAS',
        type: 'currency',
        field: 'roas_total',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    units_online_device: {
        label: 'Online Units',
        type: 'decimal',
        field: 'units_online',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    units_instore_device: {
        label: 'In Store Units',
        type: 'decimal',
        field: 'units_instore',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    units_total_device: {
        label: 'Total Units',
        type: 'decimal',
        field: 'units_total',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    revenue_total_device: {
        label: 'Total Revenue',
        type: 'currency',
        field: 'revenue_total',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    revenue_online_device: {
        label: 'Online Revenue',
        type: 'currency',
        field: 'revenue_online',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
    revenue_instore_device: {
        label: 'In Store Revenue',
        type: 'currency',
        field: 'revenue_instore',
        sortable: true,
        lineBreak: false,
        hideIfNoData: true,
    },
};
