import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { updateAccountAction } from 'containers/OrganizationPage/slice';
import DialogBox from '../../../../components/DialogBox';
import AddEditAccountForm from '../Shared/AddEditAccountForm';

export function EditAccountFlyout({ updateAccount, closeFlyout, account, accounts }) {
    const [submitValues, setSubmitValues] = useState(null);

    const onSubmit = (values) => {
        setSubmitValues(values);
    };

    const initialValues = useMemo(
        () => ({
            providerBrandId: account.providerBrandId,
            providerBrandName: account.providerBrandName || '',
            createdAt: account.createdAt || '',
            providerAccountId: account.providerAccountId || '',
            providerAccountName: account.providerAccountName || '',
            providerAccountOrgId: account.providerAccountOrgId || '',
            providerAccountOrgName: account.providerAccountOrgName || '',
            providerName: account.providerName || '',
            providerAccountType: account.providerAccountType || '',
            updatedAt: account.updatedAt || '',
            myadsBrandId: account.myadsBrandId || '',
            providerAccountIdUpdated: '',
        }),
        [account],
    );

    const onSubmitEditAccountCancel = () => {
        setSubmitValues(null);
    };

    const onEditAccountSubmitProceed = () => {
        if (submitValues) {
            const newAccountValues = {
                orgId: account.providerAccountOrgId,
                providerAccountOrgName: account.providerAccountOrgName,
                provider: account.providerName,
                providerBrandId: account.providerBrandId,
                providerBrandName: account.providerBrandName,
                providerAccountId: account.providerAccountId,
                providerAccountIdUpdated: submitValues.providerAccountId,
                providerAccountName: submitValues.providerAccountName,
                providerAccountType: account.providerAccountType,
                brandId: account.myadsBrandId,
                id: account.id,
                createdAt: account.createdAt || '',
                updatedAt: account.updatedAt || '',
            };
            updateAccount(newAccountValues);
            closeFlyout();
        }
    };

    return (
        <>
            <AddEditAccountForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitDisabled={submitValues !== null}
                accounts={accounts}
                isEdit
            />
            <DialogBox
                open={submitValues !== null}
                onConfirm={onEditAccountSubmitProceed}
                onCancel={onSubmitEditAccountCancel}
                title="Update Account"
                message="Are you sure you want to update the Account?"
                confirmText="Update Account"
            />
        </>
    );
}

EditAccountFlyout.propTypes = {
    updateAccount: PropTypes.func,
    closeFlyout: PropTypes.func,
    account: PropTypes.object,
    accounts: PropTypes.array,
};

export function mapDispatchToProps(dispatch) {
    return {
        updateAccount: (values) => dispatch(updateAccountAction(values)),
    };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(EditAccountFlyout);
