import * as vehicles from '../../vehicleConstants';

export const sponsoredColumns = {
    product: [
        'sku_id',
        'description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'direct_click_quantity',
        'direct_click_revenue',
        'direct_view_quantity',
        'direct_view_revenue',
        'related_click_quantity',
        'related_click_revenue',
        'quantity',
        'cpu_total',
        'revenue',
        'roas',
        'active_last_30',
    ],
    keyword: [
        // Keyword,
        'keyword',
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Average CPC,
        'average_cpc',
        // Click Units,
        'click_units',
        // CLick Sales,
        'click_sales',
        // CLick ROAS,
        'click_roas',
        // Click ROAS (%),
        'click_roas_percent',
        // View Through Units,
        'view_units',
        // View Through Sales,
        'view_sales',
        // View Through ROAS,
        'view_roas',
        // View Through ROAS (%),
        'view_roas_percent',
        // Click + View Units
        'click_view_units',
        // Click + View Sales
        'click_view_sales',
        // Click + View ROAS
        'click_view_roas',
        // Click + View ROAS (%)
        'click_view_roas_percent',
        // Click + View Total Cost Per Unit (CPU)
        'click_view_cpu_total',
    ],
    placement: [
        // Placement Type,
        'placement_type',
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Ave. CPC,
        'average_cpc',
        // Click Units,
        'click_units',
        // CLick Sales,
        'click_sales',
        // Click ROAS,
        'click_roas',
        // Click ROAS (%),
        'click_roas_percent',
        // View Through Units,
        'view_units',
        // View Through Sales,
        'view_sales',
        // View Through ROAS,
        'view_roas',
        // View Through ROAS (%),
        'view_roas_percent',
        // Click + View Units
        'click_view_units',
        // Click + View Sales
        'click_view_sales',
        // Click + View ROAS
        'click_view_roas',
        // Click + View ROAS (%)
        'click_view_roas_percent',
        // Click + View Total Cost Per Unit (CPU)
        'click_view_cpu_total',
    ],
    platform: [
        // Platform,
        'platform',
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Average CPC,
        'average_cpc',
        // Click Units,
        'click_units',
        // Click Sales,
        'click_sales',
        // Click ROAS,
        'click_roas',
        // Click ROAS (%),
        'click_roas_percent',
        // View Through Units,
        'view_units',
        // View Through Sales,
        'view_sales',
        // View Through ROAS,
        'view_roas',
        // View Through ROAS (%),
        'view_roas_percent',
        // View Through Total Cost Per Unit (CPU)
        'click_view_units',
        // Click + View Sales
        'click_view_sales',
        // Click + View ROAS
        'click_view_roas',
        // Click + View ROAS (%)
        'click_view_roas_percent',
        // Click + View Total Cost Per Unit (CPU)
        'click_view_cpu_total',
    ],
    opportunities: [
        'campaign_name',
        'account_name',
        'capout_time',
        // Number of Active SKUs
        'number_of_active_skus',
        // % of Traffic Active
        'pct_of_days_traffic_active',
        'clicks',
        'missed_clicks',
        // Click Opportunity
        'click_opportunity',
        'ad_spend',
        'missed_ad_spend',
        // Spend Opportunity
        'spend_opportunity',
        'revenue',
        'missed_revenue',
        // Revenue Opportunity
        'revenue_opportunity',
        // Daily Avg. Clicks
        'avg_clicks',
        // Daily Avg. Missed Clicks
        'avg_missed_clicks',
        // Daily Avg. Click Opportunity
        'avg_clicks_opportunity',
        // Daily Avg. Spend
        'avg_ad_spend',
        // Daily Avg. Missed Spend
        'avg_missed_ad_spend',
        // Daily Avg. Spend Opportunity
        'avg_spend_opportunity',
        // Daily Avg. Revenue
        'avg_revenue',
        // Daily Avg. Missed Revenue
        'avg_missed_revenue',
        // Daily Avg. Revenue Opportunity
        'avg_revenue_opportunity',
    ],
};

export const defaultColumns = {
    product: [
        'sku_id',
        'description',
        'impressions',
        'clicks',
        'ctr',
        'spend',
        'direct_click_quantity',
        'direct_click_revenue',
        'direct_view_quantity',
        'direct_view_revenue',
        'related_click_quantity',
        'related_click_revenue',
        'quantity',
        'revenue',
        'roas',
        'active_last_30',
    ],
    keyword: [
        // Keyword,
        'keyword',
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Average CPC,
        'average_cpc',
        // Click Units,
        'click_units',
        // CLick Sales,
        'click_sales',
        // CLick ROAS,
        'click_roas',
        // Click ROAS (%),
        'click_roas_percent',
        // View Through Units,
        'view_units',
        // View Through Sales,
        'view_sales',
        // View Through ROAS,
        'view_roas',
        // View Through ROAS (%),
        'view_roas_percent',
        // Click + View Units
        'click_view_units',
        // Click + View Sales
        'click_view_sales',
        // Click + View ROAS
        'click_view_roas',
        // Click + View ROAS (%)
        'click_view_roas_percent',
    ],
    placement: [
        // Placement Type,
        'placement_type',
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Ave. CPC,
        'average_cpc',
        // Click Units,
        'click_units',
        // CLick Sales,
        'click_sales',
        // Click ROAS,
        'click_roas',
        // Click ROAS (%),
        'click_roas_percent',
        // View Through Units,
        'view_units',
        // View Through Sales,
        'view_sales',
        // View Through ROAS,
        'view_roas',
        // View Through ROAS (%),
        'view_roas_percent',
        // Click + View Units
        'click_view_units',
        // Click + View Sales
        'click_view_sales',
        // Click + View ROAS
        'click_view_roas',
        // Click + View ROAS (%)
        'click_view_roas_percent',
    ],
    platform: [
        // Platform,
        'platform',
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Average CPC,
        'average_cpc',
        // Click Units,
        'click_units',
        // Click Sales,
        'click_sales',
        // Click ROAS,
        'click_roas',
        // Click ROAS (%),
        'click_roas_percent',
        // View Through Units,
        'view_units',
        // View Through Sales,
        'view_sales',
        // View Through ROAS,
        'view_roas',
        // View Through ROAS (%),
        'view_roas_percent',
        // Click + View Units
        'click_view_units',
        // Click + View Sales
        'click_view_sales',
        // Click + View ROAS
        'click_view_roas',
        // Click + View ROAS (%)
        'click_view_roas_percent',
    ],
    opportunities: [
        'campaign_name',
        'account_name',
        'capout_time',
        // Number of Active SKUs
        'number_of_active_skus',
        // % of Traffic Active
        'pct_of_days_traffic_active',
        'clicks',
        'missed_clicks',
        // Click Opportunity
        'click_opportunity',
        'ad_spend',
        'missed_ad_spend',
        // Spend Opportunity
        'spend_opportunity',
        'revenue',
        'missed_revenue',
        // Revenue Opportunity
        'revenue_opportunity',
        // Daily Avg. Clicks
        'avg_clicks',
        // Daily Avg. Missed Clicks
        'avg_missed_clicks',
        // Daily Avg. Click Opportunity
        'avg_clicks_opportunity',
        // Daily Avg. Spend
        'avg_ad_spend',
        // Daily Avg. Missed Spend
        'avg_missed_ad_spend',
        // Daily Avg. Spend Opportunity
        'avg_spend_opportunity',
        // Daily Avg. Revenue
        'avg_revenue',
        // Daily Avg. Missed Revenue
        'avg_missed_revenue',
        // Daily Avg. Revenue Opportunity
        'avg_revenue_opportunity',
    ],
};

export const modifyColumns = {
    product: [
        'sku_id',
        'description',
        'brand',
        'dept_id',
        'dept_name',
        'class_id',
        'class_name',
        'subclass_id',
        'subclass_name',
        'impressions',
        'clicks',
        'ctr',
        'revenue',
        'roas',
        'quantity',
        'cpu_total',
        'spend',
        'active_last_30',
        'direct_click',
        'related_click',
        'direct_view',
    ],
    keyword: [
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Average CPC,
        'average_cpc',
        'click_total',
        'view_total',
        'click_view_total',
        'click_view_cpu_total',
    ],
    placement: [
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Ave. CPC,
        'average_cpc',
        'click_total',
        'view_total',
        'click_view_total',
        'click_view_cpu_total',
    ],
    platform: [
        // Impressions,
        'impressions',
        // Clicks,
        'clicks',
        // CTR,
        'ctr',
        // Delivered Spend,
        'spend',
        // Average CPC,
        'average_cpc',
        'click_total',
        'view_total',
        'click_view_total',
        'click_view_cpu_total',
    ],
    opportunities: [
        'account_name',
        'capout_time',
        'number_of_active_skus',
        'pct_of_days_traffic_active',
        'revenue',
        'missed_revenue',
        'ad_spend',
        'missed_ad_spend',
        'clicks',
        'missed_clicks',
        'total',
        'daily_average',
        'daily_average_missed',
    ],
};

// This is the column map specifically for the sponsored campaign table feature
export const columnMap = {
    sku_id: {
        label: 'SKU',
        type: 'string',
        field: 'sku_id',
        sortable: true,
        lineBreak: false,
    },
    description: {
        label: 'Description',
        type: 'description',
        field: 'description',
        sortable: true,
        lineBreak: false,
    },
    brand: {
        label: 'Brand',
        type: 'description',
        field: 'brand',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_id: {
        label: 'Department ID',
        type: 'description',
        field: 'dept_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    dept_name: {
        label: 'Department Name',
        type: 'description',
        field: 'dept_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_id: {
        label: 'Class ID',
        type: 'description',
        field: 'class_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    class_name: {
        label: 'Class Name',
        type: 'description',
        field: 'class_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_id: {
        label: 'SubClass ID',
        type: 'description',
        field: 'subclass_id',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    subclass_name: {
        label: 'SubClass Name',
        type: 'description',
        field: 'subclass_name',
        sortable: true,
        lineBreak: false,
        totalHidden: true,
    },
    impressions: {
        label: 'Impressions',
        type: 'number',
        field: 'impressions',
        sortable: true,
        lineBreak: false,
    },
    clicks: {
        label: 'Clicks',
        type: 'number',
        field: 'clicks',
        sortable: true,
        lineBreak: false,
    },
    ctr: {
        label: 'CTR',
        type: 'percent_decimal',
        field: 'ctr',
        sortable: true,
        lineBreak: false,
    },
    spend: {
        label: 'Delivered Spend',
        type: 'currency',
        field: 'spend',
        sortable: true,
        lineBreak: false,
    },
    direct_click_quantity: {
        label: 'Direct Click Units',
        type: 'decimal',
        field: 'direct_click_quantity',
        sortable: true,
        lineBreak: false,
    },
    direct_click_revenue: {
        label: 'Direct Click Revenue',
        type: 'currency',
        field: 'direct_click_revenue',
        sortable: true,
        lineBreak: false,
    },
    direct_view_quantity: {
        label: 'Direct View Units',
        type: 'decimal',
        field: 'direct_view_quantity',
        sortable: true,
        lineBreak: false,
    },
    direct_view_revenue: {
        label: 'Direct View Revenue',
        type: 'currency',
        field: 'direct_view_revenue',
        sortable: true,
        lineBreak: false,
    },
    related_click_quantity: {
        label: 'Related Click Units',
        type: 'decimal',
        field: 'related_click_quantity',
        sortable: true,
        lineBreak: false,
    },
    related_click_revenue: {
        label: 'Related Click Revenue',
        type: 'currency',
        field: 'related_click_revenue',
        sortable: true,
        lineBreak: false,
    },
    revenue: {
        label: 'Revenue',
        type: 'currency',
        field: 'revenue',
        sortable: true,
        lineBreak: false,
    },
    quantity: {
        label: 'Total Units',
        type: 'decimal',
        field: 'quantity',
        sortable: true,
        lineBreak: false,
    },
    roas: {
        label: 'ROAS',
        type: 'currency',
        field: 'roas',
        sortable: true,
        lineBreak: false,
    },
    active_last_30: {
        label: 'Active Last 30',
        type: 'boolean',
        field: 'active_last_30',
        sortable: true,
        lineBreak: false,
    },
    keyword: {
        label: 'Keyword',
        type: 'description',
        field: 'keyword',
        sortable: true,
        lineBreak: false,
    },
    average_cpc: {
        label: 'Avg. CPC',
        type: 'currency',
        field: 'average_cpc',
        sortable: true,
        lineBreak: false,
    },
    click_units: {
        label: 'Click Units',
        type: 'decimal',
        field: 'click_units',
        sortable: true,
        lineBreak: false,
    },
    click_sales: {
        label: 'Click Revenue',
        type: 'currency',
        field: 'click_sales',
        sortable: true,
        lineBreak: false,
    },
    click_roas: {
        label: 'Click ROAS',
        type: 'currency',
        field: 'click_roas',
        sortable: true,
        lineBreak: false,
    },
    click_roas_percent: {
        label: 'Click ROAS (%)',
        type: 'percent_decimal',
        field: 'click_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    view_units: {
        label: 'View Through Units',
        type: 'decimal',
        field: 'view_units',
        sortable: true,
        lineBreak: false,
    },
    view_sales: {
        label: 'View Through Revenue',
        type: 'currency',
        field: 'view_sales',
        sortable: true,
        lineBreak: false,
    },
    view_roas: {
        label: 'View Through ROAS',
        type: 'currency',
        field: 'view_roas',
        sortable: true,
        lineBreak: false,
    },
    view_roas_percent: {
        label: 'View Through ROAS (%)',
        type: 'percent_decimal',
        field: 'view_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    cpu_total: {
        label: 'Total CPU',
        type: vehicles.COL_TYPE_CURRENCY,
        field: 'cpu',
        sortable: true,
        lineBreak: false,
    },
    click_view_units: {
        label: 'Click + View Units',
        type: 'decimal',
        field: 'click_view_units',
        sortable: true,
        lineBreak: false,
    },
    click_view_sales: {
        label: 'Click + View Revenue',
        type: 'currency',
        field: 'click_view_sales',
        sortable: true,
        lineBreak: false,
    },
    click_view_roas: {
        label: 'Click + View ROAS',
        type: 'currency',
        field: 'click_view_roas',
        sortable: true,
        lineBreak: false,
    },
    click_view_roas_percent: {
        label: 'Click + View ROAS (%)',
        type: 'percent_decimal',
        field: 'click_view_roas_percent',
        sortable: true,
        lineBreak: false,
    },
    click_view_cpu_total: {
        label: 'Click + View Total CPU',
        type: vehicles.COL_TYPE_CURRENCY,
        field: 'cpu',
        sortable: true,
        lineBreak: false,
    },
    placement_type: {
        label: 'Placement Type',
        type: 'description',
        field: 'placement_type',
        sortable: true,
        lineBreak: false,
    },
    platform: {
        label: 'Platform',
        type: 'description',
        field: 'platform',
        sortable: true,
        lineBreak: false,
    },
    campaign_name: {
        label: 'Campaign Name',
        type: 'description',
        field: 'campaign_name',
        sortable: true,
        lineBreak: false,
    },
    account_name: {
        label: 'Account Name',
        type: 'description',
        field: 'account_name',
        sortable: true,
        lineBreak: false,
    },
    capout_time: {
        label: 'Capout Time',
        type: 'string',
        field: 'capout_time',
        sortable: true,
        lineBreak: false,
    },
    number_of_active_skus: {
        label: '# of Active SKUS',
        type: 'number',
        field: 'number_of_active_skus',
        sortable: true,
        lineBreak: false,
    },
    pct_of_days_traffic_active: {
        label: '% of Traffic Active',
        type: 'percent_decimal',
        field: 'pct_of_days_traffic_active',
        sortable: true,
        lineBreak: false,
    },
    missed_clicks: {
        label: 'Missed Clicks',
        type: 'number',
        field: 'missed_clicks',
        sortable: true,
        lineBreak: false,
    },
    click_opportunity: {
        label: 'Total Click Opportunity',
        type: 'number',
        field: 'click_opportunity',
        sortable: true,
        lineBreak: false,
    },
    ad_spend: {
        label: 'Delivered Spend',
        type: 'currency',
        field: 'ad_spend',
        sortable: true,
        lineBreak: false,
    },
    missed_ad_spend: {
        label: 'Missed Spend',
        type: 'currency',
        field: 'missed_ad_spend',
        sortable: true,
        lineBreak: false,
    },
    spend_opportunity: {
        label: 'Total Spend Opportunity',
        type: 'currency',
        field: 'spend_opportunity',
        sortable: true,
        lineBreak: false,
    },
    missed_revenue: {
        label: 'Missed Revenue',
        type: 'currency',
        field: 'missed_revenue',
        sortable: true,
        lineBreak: false,
    },
    revenue_opportunity: {
        label: 'Total Revenue Opportunity',
        type: 'currency',
        field: 'revenue_opportunity',
        sortable: true,
        lineBreak: false,
    },
    avg_clicks: {
        label: 'Daily Avg. Clicks',
        type: 'decimal',
        field: 'avg_clicks',
        sortable: true,
        lineBreak: false,
    },
    avg_clicks_opportunity: {
        label: 'Daily Avg. Click Opportunity',
        type: 'decimal',
        field: 'avg_clicks_opportunity',
        sortable: true,
        lineBreak: false,
    },
    avg_missed_clicks: {
        label: 'Daily Avg. Missed Clicks',
        type: 'decimal',
        field: 'avg_missed_clicks',
        sortable: true,
        lineBreak: false,
    },
    avg_ad_spend: {
        label: 'Daily Avg. Delivered Spend',
        type: 'currency',
        field: 'avg_ad_spend',
        sortable: true,
        lineBreak: false,
    },
    avg_missed_ad_spend: {
        label: 'Daily Avg. Missed Spend',
        type: 'currency',
        field: 'avg_missed_ad_spend',
        sortable: true,
        lineBreak: false,
    },
    avg_spend_opportunity: {
        label: 'Daily Avg. Spend Opportunity',
        type: 'currency',
        field: 'avg_spend_opportunity',
        sortable: true,
        lineBreak: false,
    },
    avg_revenue: {
        label: 'Daily Avg. Revenue',
        type: 'currency',
        field: 'avg_revenue',
        sortable: true,
        lineBreak: false,
    },
    avg_revenue_opportunity: {
        label: 'Daily Avg. Revenue Opportunity',
        type: 'currency',
        field: 'avg_revenue_opportunity',
        sortable: true,
        lineBreak: false,
    },
    avg_missed_revenue: {
        label: 'Daily Avg. Missed Revenue',
        type: 'currency',
        field: 'avg_missed_revenue',
        sortable: true,
        lineBreak: false,
    },
};

// This is the column map specifically for the modify columns feature
export const columnFilterMap = {
    description: {
        label: 'Description',
        column: 'Description',
    },
    brand: {
        label: 'Brand',
        column: 'brand',
    },
    dept_id: {
        label: 'Department ID',
        column: 'dept_id',
    },
    dept_name: {
        label: 'Department Name',
        column: 'dept_name',
    },
    class_id: {
        label: 'Class ID',
        column: 'class_id',
    },
    class_name: {
        label: 'Class Name',
        column: 'class_name',
    },
    subclass_id: {
        label: 'SubClass ID',
        column: 'subclass_id',
    },
    subclass_name: {
        label: 'SubClass Name',
        column: 'subclass_name',
    },
    impressions: {
        label: 'Impressions',
        column: 'impressions',
    },
    clicks: {
        label: 'Clicks',
        column: 'clicks',
    },
    ctr: {
        label: 'CTR',
        column: 'ctr',
    },
    spend: {
        label: 'Delivered Spend',
        column: 'spend',
    },
    revenue: {
        label: 'Revenue',
        column: 'revenue',
    },
    quantity: {
        label: 'Total Units',
        column: 'quantity',
    },
    cpu_total: {
        label: 'CPU',
        column: 'click_view_cpu_total',
    },
    roas: {
        label: 'ROAS',
        column: 'roas',
    },
    active_last_30: {
        label: 'Active Last 30',
        column: 'active_last_30',
    },
    keyword: {
        label: 'Keyword',
        column: 'keyword',
    },
    average_cpc: {
        label: 'Avg. CPC',
        column: 'average_cpc',
    },
    ad_spend: {
        label: 'Delivered Spend',
        column: 'ad_spend',
    },
    account_name: {
        label: 'Account Name',
        column: 'account_name',
    },
    capout_time: {
        label: 'Capout Time',
        column: 'capout_time',
    },
    number_of_active_skus: {
        label: '# of Active SKUS',
        column: 'number_of_active_skus',
    },
    pct_of_days_traffic_active: {
        label: '% of Traffic Active',
        column: 'pct_of_days_traffic_active',
    },
    missed_clicks: {
        label: 'Missed Clicks',
        column: 'missed_clicks',
    },
    missed_ad_spend: {
        label: 'Missed Spend',
        column: 'missed_ad_spend',
    },
    missed_revenue: {
        label: 'Missed Revenue',
        column: 'missed_revenue',
    },
    direct_click: {
        label: 'DIRECT CLICK:',
        children: [
            {
                label: 'Click Units',
                column: 'direct_click_quantity',
            },
            {
                label: 'Click Revenue',
                column: 'direct_click_revenue',
            },
        ],
    },
    related_click: {
        label: 'RELATED CLICK:',
        children: [
            {
                label: 'Click Units',
                column: 'related_click_quantity',
            },
            {
                label: 'Click Revenue',
                column: 'related_click_revenue',
            },
        ],
    },
    direct_view: {
        label: 'DIRECT VIEW-THROUGH:',
        children: [
            {
                label: 'Units',
                column: 'direct_view_quantity',
            },
            {
                label: 'Revenue',
                column: 'direct_view_revenue',
            },
        ],
    },
    click_total: {
        label: 'CLICK-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'click_units',
            },
            {
                label: 'Revenue',
                column: 'click_sales',
            },
            {
                label: 'ROAS',
                column: 'click_roas',
            },
            {
                label: 'ROAS (%)',
                column: 'click_roas_percent',
            },
        ],
    },
    view_total: {
        label: 'VIEW-THROUGH TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'view_units',
            },
            {
                label: 'Revenue',
                column: 'view_sales',
            },
            {
                label: 'ROAS',
                column: 'view_roas',
            },
            {
                label: 'ROAS (%)',
                column: 'view_roas_percent',
            },
        ],
    },
    click_view_total: {
        label: 'CLICK + VIEW TOTAL:',
        children: [
            {
                label: 'Units',
                column: 'click_view_units',
            },
            {
                label: 'Revenue',
                column: 'click_view_sales',
            },
            {
                label: 'ROAS',
                column: 'click_view_roas',
            },
            {
                label: 'ROAS (%)',
                column: 'click_view_roas_percent',
            },
            {
                label: 'CPU',
                column: 'click_view_cpu_total',
            },
        ],
    },
    total: {
        label: 'TOTAL:',
        children: [
            {
                label: 'Revenue Opportunity',
                column: 'revenue_opportunity',
            },
            {
                label: 'Spend Opportunity',
                column: 'spend_opportunity',
            },
            {
                label: 'Click Opportunity',
                column: 'click_opportunity',
            },
        ],
    },
    daily_average: {
        label: 'DAILY AVERAGE:',
        children: [
            {
                label: 'Clicks',
                column: 'avg_clicks',
            },
            {
                label: 'Click Opportunity',
                column: 'avg_clicks_opportunity',
            },
            {
                label: 'Revenue',
                column: 'avg_revenue',
            },
            {
                label: 'Revenue Opportunity',
                column: 'avg_revenue_opportunity',
            },
            {
                label: 'Delivered Spend',
                column: 'avg_ad_spend',
            },
            {
                label: 'Spend Opportunity',
                column: 'avg_spend_opportunity',
            },
        ],
    },
    daily_average_missed: {
        label: 'DAILY AVERAGE MISSED:',
        children: [
            {
                label: 'Revenue',
                column: 'avg_missed_revenue',
            },
            {
                label: 'Spend',
                column: 'avg_missed_ad_spend',
            },
            {
                label: 'Clicks',
                column: 'avg_missed_clicks',
            },
        ],
    },
};
