import CreatePasswordForm from 'components/CreatePasswordForm';
import React, { useState, useEffect, useCallback } from 'react';
import {
    inviteUser,
    checkOTP,
    phoneOTPRequest,
    updateIDAMUserInfo,
    setIDAMUserPassword,
    getIDAMUserInfo,
    EMAIL_CODE_ACCEPTED,
    getRegistrationStatus,
    PHONE_CODE_ACCEPTED,
    PASSWORD_SET,
    USER_UPDATED,
} from 'api/user-idam';
import { useLocation } from 'react-router';
import { FORM_ERROR } from 'final-form';
import { RequestStatus } from 'shared/constants';

import { OTPPage } from './otp';
import UserUpdatePage from './user-update';
import IDAMThankYouPage from './thankyou';
import LoadScreen from './LoadScreen';
import ErrorWithContact from './ErrorWithContact';
import useFeatureFlags from '../../utils/featureFlags/useFeatureFlags';

const steps = {
    LOADING: 'LOADING',
    EMAIL_OTP: 'email-otp',
    PHONE_OTP: 'phone-otp',
    USER_UPDATE: 'user-update',
    PASSWORD_CHANGE: 'password',
    THANK_YOU: 'thank-you',
    ERROR: 'ERROR',
};

export default function IDAMInvite() {
    const [step, setStep] = useState(steps.LOADING);
    const [userInfo, setUserInfo] = useState({});
    const [otpStatus, setOtpStatus] = useState({
        loading: false,
        error: null,
        otpSendStatus: RequestStatus.INIT,
    });
    const [passwordError, setPasswordError] = useState(null);
    const [pairingSession, setPairingSession] = useState('');

    const flags = useFeatureFlags();

    const { search } = useLocation();

    const getUserData = useCallback(async () => {
        const data = await getIDAMUserInfo(flags);
        setUserInfo(data);
        return data;
    }, [flags]);

    const isUserChanged = (updatedInfo) =>
        !updatedInfo.phone && userInfo.first === updatedInfo.first && userInfo.last === updatedInfo.last;

    const sendEmailOTP = async () => {
        try {
            const params = new URLSearchParams(search);
            const inviteId = params.get('uniqueID');
            setOtpStatus({ ...otpStatus, otpSendStatus: RequestStatus.LOADING });
            const code = await inviteUser(inviteId);
            setPairingSession(code);
            setOtpStatus({ ...otpStatus, otpSendStatus: RequestStatus.DONE });
        } catch (e) {
            if (e.response.status === 410) {
                setOtpStatus({
                    ...otpStatus,
                    otpSendStatus: RequestStatus.ERROR,
                    error: ErrorWithContact('Your invitation has expired. Please contact support.', 'contact support'),
                });
            } else {
                setOtpStatus({
                    ...otpStatus,
                    otpSendStatus: RequestStatus.ERROR,
                    error: ErrorWithContact(
                        'Something went wrong, please try again or contact support.',
                        'contact support',
                    ),
                });
            }
        }
    };

    const sendPhoneOTP = async (phone) => {
        const code = await phoneOTPRequest(phone, flags);
        setPairingSession(code);
    };

    const checkCode = async (code, onSuccess) => {
        try {
            setOtpStatus({
                ...otpStatus,
                loading: true,
            });
            const result = await checkOTP({ code, pairingSession, flags });
            if (result === EMAIL_CODE_ACCEPTED) {
                setOtpStatus({
                    loading: false,
                    error: null,
                });
                onSuccess();
            } else {
                throw new Error('Unknown code result.');
            }
        } catch (e) {
            if (e.response.status === 401 || e.response.status === 410) {
                setOtpStatus({
                    loading: false,
                    error: 'Invalid Code',
                });
            } else {
                setOtpStatus({
                    loading: false,
                    error: ErrorWithContact(
                        'Something went wrong, please try again or contact support.',
                        'contact support',
                    ),
                });
            }
        }
    };

    const updateInfo = async (updatedInfo, onSuccess) => {
        if (isUserChanged(updatedInfo)) {
            setStep(steps.PASSWORD_CHANGE);
            return undefined;
        }

        try {
            await updateIDAMUserInfo(updatedInfo, flags);
            onSuccess(updatedInfo);
        } catch (e) {
            return {
                [FORM_ERROR]: ErrorWithContact(
                    "We've encountered an error. Please try again or contact support.",
                    'contact support',
                ),
            };
        }
        return undefined;
    };

    const setPassword = async (password, onSuccess) => {
        setPasswordError(null);
        try {
            await setIDAMUserPassword({
                email: userInfo.email,
                password,
                flags,
            });
            onSuccess();
        } catch (e) {
            setPasswordError(
                ErrorWithContact('Password has not been set. Please try again or contact support.', 'contact support'),
            );
        }
    };

    const params = new URLSearchParams(search);
    const devStep = params.get('step');

    useEffect(() => {
        // Allow devs to skip to a specific step
        if (devStep && step !== devStep) {
            setStep(devStep);
            return;
        }

        if (step !== steps.LOADING) {
            return;
        }

        const progressPromise = getUserData()
            .then((user) => getRegistrationStatus(user.uuid, flags))
            .then((status) => {
                switch (status) {
                    case EMAIL_CODE_ACCEPTED:
                        return steps.USER_UPDATE;
                    case PHONE_CODE_ACCEPTED:
                        return steps.PASSWORD_CHANGE;
                    case PASSWORD_SET:
                        return steps.THANK_YOU;
                    case USER_UPDATED:
                        return steps.USER_UPDATE;
                    default:
                        return steps.EMAIL_OTP;
                }
            })
            .catch(() => steps.EMAIL_OTP);

        // Wait a tiny delay so that the loading screen doesn't create a screen flash
        // if the page loads very quickly
        // eslint-disable-next-line no-unused-vars
        setTimeout(async () => {
            const nextStep = await progressPromise;
            setStep(nextStep);
        }, 1000);
    }, [step, devStep, flags, getUserData]);

    switch (step) {
        case steps.LOADING:
            return <LoadScreen />;
        case steps.EMAIL_OTP:
            return (
                <OTPPage
                    onSubmit={async ({ code }) => {
                        await checkCode(code, async () => {
                            await getUserData();
                            setStep(steps.USER_UPDATE);
                        });
                    }}
                    onSendCode={sendEmailOTP}
                    status={otpStatus}
                    type="email"
                />
            );
        case steps.USER_UPDATE:
            return (
                <UserUpdatePage
                    firstName={userInfo.first}
                    lastName={userInfo.last}
                    email={userInfo.email}
                    onSubmit={(info) =>
                        updateInfo(info, (updatedInfo) => {
                            if (updatedInfo.phone) {
                                sendPhoneOTP(updatedInfo.phone);
                                setStep(steps.PHONE_OTP);
                            } else {
                                setStep(steps.PASSWORD_CHANGE);
                            }
                        })
                    }
                />
            );
        case steps.PHONE_OTP:
            return (
                <OTPPage
                    onSubmit={async ({ code }) => {
                        await checkCode(code, () => setStep(steps.PASSWORD_CHANGE));
                    }}
                    status={otpStatus}
                    type="phone"
                />
            );
        case steps.PASSWORD_CHANGE:
            return (
                <CreatePasswordForm
                    onSubmit={async (password) => {
                        await setPassword(password, () => setStep(steps.THANK_YOU));
                    }}
                    submitError={passwordError}
                />
            );
        case steps.THANK_YOU:
            return <IDAMThankYouPage />;
        default:
            return null;
    }
}
