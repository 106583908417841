import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { updateBrandAction } from 'containers/OrganizationPage/slice';
import { selectBrands } from 'containers/OrganizationPage/selectors';
import { createStructuredSelector } from 'reselect';
import DialogBox from 'components/DialogBox';
import AddEditBrandForm from '../Shared/AddEditBrandForm';

export function EditBrandFlyout({ brands, updateBrand, closeFlyout, brand }) {
    const [submitValues, setSubmitValues] = useState(null);

    const onSubmit = (values) => {
        setSubmitValues(values);
    };

    const initialValues = useMemo(
        () => ({
            name: brand.name || '',
        }),
        [brand],
    );

    const onSubmitEditBrandCancel = () => {
        setSubmitValues(null);
    };

    const onEditBrandSubmitProceed = () => {
        if (submitValues) {
            const newBrandValues = {
                name: submitValues.name,
                brandId: brand.id,
            };
            updateBrand(newBrandValues);
            closeFlyout();
        }
    };

    return (
        <>
            <AddEditBrandForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitDisabled={submitValues !== null}
                brands={brands}
                isEdit
            />
            <DialogBox
                open={submitValues !== null}
                onConfirm={onEditBrandSubmitProceed}
                onCancel={onSubmitEditBrandCancel}
                title="Update Brand"
                message="Are you sure you want to update the Brand?"
                confirmText="Update Brand"
            />
        </>
    );
}

EditBrandFlyout.propTypes = {
    brand: PropTypes.object,
    brands: PropTypes.array,
    updateBrand: PropTypes.func,
    closeFlyout: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    brands: selectBrands,
});

export function mapDispatchToProps(dispatch) {
    return {
        updateBrand: (values) => dispatch(updateBrandAction(values)),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditBrandFlyout);
