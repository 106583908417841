import moment from 'moment-timezone';
import { isSponsoredRSX, isSponsoredRMP } from 'Helpers/helpers';
import {
    DISPLAY_GROUP_TYPE,
    BUILD_ONSITE_DISPLAY_GROUP_NAME,
    SPONSORED_GROUP_TYPE,
    SPONSORED_RSX_GROUP_TYPE,
    BUILD_SPONSORED_RSX_GROUP_NAME,
    SPONSORED_RMP_GROUP_TYPE,
    BUILD_SPONSORED_RMP_GROUP_NAME,
} from './constants';

export const getFurthestStartDate = (allCampaigns, campaignIds) => {
    const campaigns = allCampaigns.filter((campaign) => campaignIds.includes(campaign.campaign_id));
    const campaignStartDates = campaigns.map((campaign) => moment(campaign.start_date));

    return moment.min(campaignStartDates);
};

export const getLatestEndDate = (allCampaigns, campaignIds) => {
    const campaigns = allCampaigns.filter((campaign) => campaignIds.includes(campaign.campaign_id));
    const campaignEndDates = campaigns.map((campaign) => moment(campaign.end_date));

    return moment.max(campaignEndDates);
};

export const getGroupTypeAndName = (campaign) => {
    let groupType = DISPLAY_GROUP_TYPE;
    let groupName = BUILD_ONSITE_DISPLAY_GROUP_NAME;

    if (isSponsoredRSX(campaign)) {
        groupType = SPONSORED_RSX_GROUP_TYPE;
        groupName = BUILD_SPONSORED_RSX_GROUP_NAME;
    }
    if (isSponsoredRMP(campaign)) {
        groupType = SPONSORED_RMP_GROUP_TYPE;
        groupName = BUILD_SPONSORED_RMP_GROUP_NAME;
    }
    return { groupType, groupName };
};

export const isValidGroupName = (name) => {
    const trimed = name.trim();
    return (
        trimed.length > 0 &&
        trimed.toLowerCase() !== BUILD_ONSITE_DISPLAY_GROUP_NAME.toLowerCase() &&
        trimed.toLowerCase() !== BUILD_SPONSORED_RSX_GROUP_NAME.toLowerCase() &&
        trimed.toLowerCase() !== BUILD_SPONSORED_RMP_GROUP_NAME.toLowerCase()
    );
};

export const getCampaignsFromIds = (campaignIds, campaignHash) =>
    campaignIds.map((id) => campaignHash[id]).filter((c) => c !== undefined);

export const getStackRoute = (lastAddedGroupType, groupName) => {
    let route = '/campaigns/stacked';
    if (
        groupName !== BUILD_ONSITE_DISPLAY_GROUP_NAME &&
        groupName !== BUILD_SPONSORED_RSX_GROUP_NAME &&
        groupName !== BUILD_SPONSORED_RMP_GROUP_NAME
    ) {
        route += '/my-reports';
    } else if (lastAddedGroupType === DISPLAY_GROUP_TYPE) {
        route += '/build-display';
    } else if (lastAddedGroupType === SPONSORED_RSX_GROUP_TYPE || lastAddedGroupType === SPONSORED_GROUP_TYPE) {
        route += '/build-sponsored';
    } else if (lastAddedGroupType === SPONSORED_RMP_GROUP_TYPE) {
        route += '/build-sponsored-rmp';
    }

    return route;
};

export const getDefaultGroupName = (groupType) => {
    switch (groupType) {
        case DISPLAY_GROUP_TYPE:
            return BUILD_ONSITE_DISPLAY_GROUP_NAME;
        case SPONSORED_RSX_GROUP_TYPE:
            return BUILD_SPONSORED_RSX_GROUP_NAME;
        case SPONSORED_RMP_GROUP_TYPE:
            return BUILD_SPONSORED_RMP_GROUP_NAME;
        default:
            return BUILD_ONSITE_DISPLAY_GROUP_NAME;
    }
};
