/**
 *
 * BrandListTable
 *
 */
import React, { useMemo } from 'react';
import { campaignDateFormatter, listFormatter } from 'Helpers/table-formatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { createStructuredSelector } from 'reselect';
import { FlyoutAction } from 'shared/constants';
import Table from 'components/@shared/Table';
import { updateBrandPageNumber } from '../../slice';
import { selectBrandPageNumber } from '../../selectors';

const brandColumns = {
    name: {
        field: 'name',
        label: 'Brand',
        sortable: true,
    },
    orgNames: {
        field: 'orgNames',
        label: 'Organization',
        sortable: true,
        format: listFormatter,
    },
    createdAt: {
        field: 'createdAt',
        label: 'Created on',
        sortable: true,
        format: campaignDateFormatter,
    },
    updatedAt: {
        field: 'updatedAt',
        label: 'Updated on',
        sortable: true,
        format: campaignDateFormatter,
    },
};

function containIgnoreCase(object, fields, skw) {
    if (!skw) {
        return true;
    }
    if (fields) {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of fields) {
            if (field === 'name') {
                const string = `${object[field]}`;
                if (string && string.toUpperCase().includes(skw.toUpperCase())) {
                    return true;
                }
            } else if (field === 'orgNames') {
                const orgNames = object[field];
                if (orgNames) {
                    for (let i = 0; i < orgNames.length; i++) {
                        if (orgNames[i] && orgNames[i].toUpperCase().includes(skw.toUpperCase())) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
}

function BrandListTable({
    brands,
    setFlyoutStatus,
    setSelectedItem,
    searchKeyword,
    // Redux
    onUpdatePageNumber,
    pageNumber,
}) {
    const filteredBrands = useMemo(
        () => brands.filter((brand) => containIgnoreCase(brand, ['name', 'orgNames'], searchKeyword)),
        [brands, searchKeyword],
    );

    const tableActions = (brand) => [
        {
            label: 'Edit Brand',
            onClick: () => {
                setSelectedItem(brand);
                setFlyoutStatus(FlyoutAction.EDIT_BRAND);
            },
        },
    ];

    return (
        <div>
            <Table
                data={filteredBrands}
                columns={Object.values(brandColumns)}
                actions={tableActions}
                pageNumber={pageNumber}
                onUpdatePageNumber={onUpdatePageNumber}
                keyFn={(item) => item?.id}
                sort={{ column: brandColumns.createdAt, order: 'desc' }}
            />
        </div>
    );
}

BrandListTable.propTypes = {
    brands: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            orgNames: PropTypes.arrayOf(PropTypes.string),
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
        }),
    ).isRequired,
    onUpdatePageNumber: PropTypes.func,
    pageNumber: PropTypes.number,
    setFlyoutStatus: PropTypes.func,
    setSelectedItem: PropTypes.func,
    searchKeyword: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    pageNumber: selectBrandPageNumber,
});

export function mapDispatchToProps(dispatch) {
    return {
        onUpdatePageNumber: (pageNumber) => {
            dispatch(updateBrandPageNumber(pageNumber));
        },
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(BrandListTable);
