/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment-timezone';
import VehicleType from 'components/VehicleType';
import BrandText from 'components/@shared/BrandText';
import Status from 'components/Status';
import { allRolesLabels } from 'components/UserForm/constants';
import { isNumber } from 'lodash';
import { Vehicle } from 'vehicles';
import { addCommasToNumber, removeTrailingZeros } from 'utils/formatData';
import ViewAction from 'components/ViewAction';
import { CAMPAIGN_TYPE_UNKNOWN } from './helpers';

const dateCache = new Map();

export class TableFormatter {
    // This mostly for table sorting with value as string or number
    value = null;

    // This is for cell rendering
    render = null;

    constructor({ value, render }) {
        this.value = value;
        this.render = render;
    }
}

export const listFormatter = (list) => {
    let display = '';
    if (Array.isArray(list)) {
        display = list.join(', ');
    }

    return new TableFormatter({
        value: display,
        render: display,
    });
};

export const campaignDateFormatter = (value) => {
    const dateInfo = dateCache.get(value);

    let millis;
    let formatted;

    if (dateInfo !== undefined) {
        millis = dateInfo.millis;
        formatted = dateInfo.formatted;
    } else {
        if (value && value !== '') {
            millis = new Date(value).getTime();
            formatted = moment(value).utc().format('YYYY-MM-DD');
        } else {
            millis = 0;
            formatted = '';
        }
        dateCache.set(value, { millis, formatted });
    }
    return new TableFormatter({
        value: millis,
        render: formatted,
    });
};

export const summaryDashboardViewAction = (value) => {
    const vehicle = Vehicle.getFromCampaignType(value);
    const vehicleKey = vehicle?.getVehicleKey();
    return new TableFormatter({
        vehicleKey,
        render: vehicleKey && vehicleKey !== CAMPAIGN_TYPE_UNKNOWN ? <ViewAction vehicle={vehicleKey} /> : '',
    });
};

export const campaignStatusFormatter = (value) =>
    new TableFormatter({
        value,
        render: value && <Status status={value} />,
    });

export const campaignTypeFormatter = (value) => {
    const vehicle = Vehicle.getFromCampaignType(value);
    const label = vehicle.getLabel();
    return new TableFormatter({
        value: label,
        render: <VehicleType vehicle={vehicle} />,
    });
};

export const groupNameFormatter = (value) => {
    const vehicle = Vehicle.getFromGroupName(value);
    const label = vehicle.getLabel();
    return new TableFormatter({
        value: label,
        render: <VehicleType vehicle={vehicle} />,
    });
};

export const rolesFormatter = (value) => {
    let formatted = '';
    if (value) {
        formatted = value
            .map((role) => `${allRolesLabels[role]}`)
            .slice()
            .sort()
            .join(', ');
    }
    return new TableFormatter({
        value,
        render: formatted,
    });
};

export const brandsFormatter = (brands) =>
    new TableFormatter({
        brands,
        render: <BrandText brands={brands} />,
    });

export const userCountFormatter = (userCount) => {
    let formatted = userCount;
    if (!isNumber(userCount)) {
        formatted = '';
    }
    return new TableFormatter({
        userCount,
        render: formatted,
    });
};

export const currencyFormatter = (value) => {
    const formattedValue = value ? `$${addCommasToNumber(value)}` : '$0';

    return new TableFormatter({
        value,
        render: formattedValue,
    });
};

export const stringFormatter = (value) => {
    const formattedValue = value || '';

    return new TableFormatter({
        value,
        render: formattedValue,
    });
};

export const numberFormatter = (value) => {
    const formattedValue = value ? addCommasToNumber(value) : '0';

    return new TableFormatter({
        value,
        render: formattedValue,
    });
};

export const percentFormatter = (value) => {
    let formattedValue = value ? value?.toFixed(2) : 0;

    formattedValue = removeTrailingZeros(formattedValue);

    return new TableFormatter({
        value,
        render: `${formattedValue}%`,
    });
};
