export const OrgPageTabs = {
    ORGANIZATIONS: {
        label: 'Organizations',
        key: 'ORGANIZATIONS',
    },
    BRANDS: {
        label: 'Brands',
        key: 'BRANDS',
    },
    ACCOUNTS: {
        label: 'Accounts',
        key: 'ACCOUNTS',
    },
};
