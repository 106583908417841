/**
 *
 * SearchBar
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';

import { makeSelectLoading, makeSelectCampaigns } from 'containers/App/selectors';

import { SearchSvg } from 'components/Svg';

import DropdownItem from 'components/DropdownItem';
import useFuse from './useFuse';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(4),
        width: '50%',
        fontFamily: theme.typography.fontFamily,
        backgroundColor: '#ffffff',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        borderRadius: '40px',
        border: '100px',
    },
    searchIcon: {
        color: theme.palette.humanBlue,
    },
}));

const SEARCH_OPTIONS = {
    shouldSort: true,
    sortFn: (a, b) => {
        let scoreComparison = a.score - b.score;
        if (scoreComparison === 0) {
            scoreComparison = moment(a.item.created_at).diff(moment(b.item.created_at));
        }

        return scoreComparison;
    },
    threshold: 0.3,
    ignoreLocation: true,
    minMatchCharLength: 2,
    ignoreFieldNorm: true,
    includeScore: true,
    keys: ['campaign_id', 'salesforce_campaign_name', 'order_id', 'criteo_id', 'proximity_campaign_id'],
};

export function SearchBar({ campaigns, loading }) {
    const { result, search, term, reset } = useFuse({
        data: campaigns,
        options: SEARCH_OPTIONS,
    });
    const history = useHistory();
    const classes = useStyles();

    /**
     * Callback fired when a user clicks on a dropdown item
     *
     * @param  {object} dataItem the meta data stored in each dropdown item
     *
     * @returns {undefined}
     */
    const navTo = (dataItem) => {
        history.push({
            pathname: `/campaigns/details/${dataItem.campaign_id}`,
        });
    };

    /**
     * Callback fired when the value changes.
     *
     * @param  {object} event the event object
     * @param  {object} value the metadata of the dropdown object
     *
     * @returns {undefined}
     */
    const onChange = (event, value) => {
        if (value?.item && get(value, 'item.campaign_id', false)) {
            navTo(value?.item);
        }
    };

    /**
     * Callback when user presses 'enter' key on search bar
     *
     * @returns {undefined}
     */
    function navToSearch() {
        history.push({
            pathname: '/search/results',
            state: {
                result,
                term,
            },
        });
    }

    /**
     * Callback fired when the input value changes.
     *
     * @param  {object} event the React SyntheticEvent object
     * @param  {object} value the current value associated with the getOptionLabel property on Autocomplete component
     * @param  {object} reason the event name performed on autocomplete
     *
     * @returns {undefined}
     */
    const onInputChange = (event, value, reason) => {
        switch (reason) {
            // on enter key pressed
            case 'reset':
                navToSearch();
                reset();
                break;
            // when input 'x' is pressed to clear search term
            case 'clear':
                reset();
                break;
            default:
                break;
        }
    };

    return (
        <Autocomplete
            id="campaign-search-bar"
            data-testid="campaign-search"
            className={classes.root}
            options={result}
            getOptionLabel={(x) => (get(x, 'salesforce_campaign_name') ? x.salesforce_campaign_name : '')}
            onChange={onChange}
            onInputChange={onInputChange}
            ListboxProps={{
                'data-testid': 'campaign-search-popup',
            }}
            loadingText="Loading..."
            filterOptions={(x) => x}
            freeSolo
            loading={loading}
            disabled={loading} // make it so if campaigns are loading can't search
            clearOnEscape
            includeInputInList
            selectOnFocus
            noOptionsText="The campaign you are searching for is not available"
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    ref={params.InputProps.ref}
                    id="search-text-field"
                    placeholder="Search by campaign name..."
                    onChange={(e) => search(e.target.value)}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        id: 'search-text-field',
                        'aria-label': 'search',
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment className={classes.searchIcon} position="end">
                                <SearchSvg />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={({ item }) => (
                <DropdownItem title={item.salesforce_campaign_name} subtitle={item.order_id} bold={item.highlight} />
            )}
        />
    );
}

SearchBar.propTypes = {
    campaigns: PropTypes.array,
    loading: PropTypes.bool,
};

SearchBar.defaultProps = {
    campaigns: [],
    loading: false,
};

const mapStateToProps = createStructuredSelector({
    campaigns: makeSelectCampaigns(),
    loading: makeSelectLoading(),
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect, memo)(SearchBar);
