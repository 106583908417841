import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    labelContainerSpace: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    inputLabel: {
        height: 14,
        padding: '5px 0px',
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    sublabel: {
        marginLeft: theme.spacing() - 4,
        color: theme.palette.darkGray,
        fontWeight: 300,
        alignSelf: 'right',
    },
}));

const Labels = ({ inputLabel, inputSublabel, labelSpaceStyle, htmlFor }) => {
    const classes = useStyles();

    return (
        <div className={labelSpaceStyle ? classes.labelContainerSpace : classes.labelContainer}>
            <InputLabel className={classes.inputLabel} htmlFor={htmlFor}>
                {inputLabel}
            </InputLabel>
            <InputLabel className={classnames(classes.inputLabel, classes.sublabel)} htmlFor={htmlFor}>
                {inputSublabel}
            </InputLabel>
        </div>
    );
};

Labels.propTypes = {
    inputLabel: PropTypes.string,
    inputSublabel: PropTypes.string,
    labelSpaceStyle: PropTypes.bool,
    htmlFor: PropTypes.string,
};

export default Labels;
