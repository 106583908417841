/**
 *
 * OrgTableContainer
 *
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import Tabs from 'components/CampaignReporting/Tabs';
import useFeatureFlags from 'utils/featureFlags/useFeatureFlags';
import ListSearch from 'components/ListSearch/ListSearch';
import { FlyoutAction } from 'shared/constants';
import { useDispatch, useSelector } from 'react-redux';
import OrganizationListTable from '../Tables/OrganizationListTable';
import { OrgPageTabs } from '../constants';
import { selectOrganizationFilter } from '../selectors';
import { updateFilteredOrganizations } from '../slice';
import OrgActionButton from '../../../components/OrgActionButton';
import BrandListTable from '../Tables/BrandListTable';
import AccountListTable from '../Tables/AccountListTable';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginTop: theme.spacing(5),
        border: `solid 1px ${theme.palette.battleGray}`,
        borderBottom: 'none',
        position: 'relative',
        backgroundColor: 'white',
    },
    headerFlexBox: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    strong: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
        fontWeight: 'bold',
        paddingRight: 16,
        color: '#1D252C',
    },
    hidden: {
        visibility: 'hidden',
    },
    search: {
        width: '236px',
        marginTop: '6px',
        paddingRight: 16,
    },
}));

export function OrgTableContainer({
    organizations,
    brands,
    accounts,
    flyoutStatus,
    setFlyoutStatus,
    setSelectedItem,
    onTabChange,
    tab = OrgPageTabs.ORGANIZATIONS.key,
    onOrgActionClick,
}) {
    const classes = useStyles();
    const featureFlags = useFeatureFlags();
    const dispatch = useDispatch();
    const orgFilter = useSelector(selectOrganizationFilter);
    const [searchKeyword, setSearchKeyword] = useState('');

    const onSearch = (keyword, includes) => {
        setSearchKeyword(keyword);
        dispatch(updateFilteredOrganizations({ includes }));
    };

    const { includes: searchIncludes } = orgFilter;

    let title = '';
    let flyoutContent = null;
    let filterAction = null;

    const openOrgFilter = () => {
        setFlyoutStatus(FlyoutAction.ORG_FILTER);
    };

    switch (tab) {
        case OrgPageTabs.ORGANIZATIONS.key:
            title = 'Organizations';
            filterAction = openOrgFilter;
            flyoutContent = (
                <OrganizationListTable
                    organizations={organizations}
                    brands={brands}
                    flyoutStatus={flyoutStatus}
                    setFlyoutStatus={setFlyoutStatus}
                    setSelectedItem={setSelectedItem}
                    searchKeyword={searchKeyword}
                    searchIncludes={searchIncludes}
                />
            );
            break;
        case OrgPageTabs.BRANDS.key:
            title = 'Brands';
            filterAction = null;
            flyoutContent = (
                <BrandListTable
                    brands={brands}
                    setFlyoutStatus={setFlyoutStatus}
                    setSelectedItem={setSelectedItem}
                    searchKeyword={searchKeyword}
                />
            );
            break;
        case OrgPageTabs.ACCOUNTS.key:
            title = 'Accounts';
            filterAction = null;
            flyoutContent = (
                <AccountListTable
                    accounts={accounts}
                    setFlyoutStatus={setFlyoutStatus}
                    setSelectedItem={setSelectedItem}
                    searchKeyword={searchKeyword}
                />
            );
            break;
        default:
            title = '';
            filterAction = null;
            break;
    }

    return (
        <div>
            <div className={classes.header}>
                <Typography component="h2" variant="h3" align="left" className={classes.headline}>
                    {title}
                </Typography>
                {featureFlags.showOrgManagement && <OrgActionButton tab={tab} onClick={onOrgActionClick} />}
                {!featureFlags.showOrgManagement && (
                    <ListSearch onSearch={onSearch} keyword={searchKeyword} includes={searchIncludes} />
                )}
            </div>
            <div>
                <div className={classes.top}>
                    <div className={featureFlags.showOrgManagement ? undefined : classes.hidden}>
                        <Tabs tabHeaders={OrgPageTabs} value={tab} onChange={(e, val) => onTabChange(val)} />
                    </div>
                    <div className={classes.headerFlexBox}>
                        {featureFlags.showOrgManagement && (
                            <ListSearch
                                className={classes.search}
                                onSearch={onSearch}
                                keyword={searchKeyword}
                                includes={searchIncludes}
                                hideCheckboxes
                            />
                        )}
                        <IconButton
                            onClick={filterAction}
                            data-testid="orgFilterFlyoutButton"
                            className={filterAction ? undefined : classes.hidden}
                        >
                            <TuneIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            {flyoutContent}
        </div>
    );
}

OrgTableContainer.propTypes = {
    brands: PropTypes.array,
    organizations: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            userCount: PropTypes.number.isRequired,
            campaignCount: PropTypes.number.isRequired,
            brands: PropTypes.array.isRequired,
            orgId: PropTypes.string.isRequired,
        }),
    ).isRequired,
    accounts: PropTypes.array,
    flyoutStatus: PropTypes.string.isRequired,
    setFlyoutStatus: PropTypes.func.isRequired,
    setSelectedItem: PropTypes.func.isRequired,
    tab: PropTypes.string.isRequired,
    onTabChange: PropTypes.func,
    onOrgActionClick: PropTypes.func.isRequired,
};
