import React, { useContext, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import Navbar from 'components/Navbar';
import FlyoutModal from 'components/FlyoutModal';
import UserDetailContent from 'components/UserDetailContent';
import IDAMPasswordUpdateContent from 'containers/IDAMUserManagement/PasswordUpdateContent';
import { AppContext } from 'shared/contexts/app-context';
import { UserManagePermissions } from 'shared/constants/user/permissions';
import Search from 'containers/SearchBar';
import { hasOrganizationRoles, hasPermission, userIsExternal } from 'Helpers/helpers';
import useFeatureFlags from 'utils/featureFlags/useFeatureFlags';
import { RequestStatus } from 'shared/constants';
import classnames from 'classnames';
import HelpMenu from './HelpMenu';
import UserMenu from './UserMenu';
import { LogoSvg } from '../../components/Svg';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: `
            0px 0px 4px -1px rgba(0,0,0,0.2),
            0px 0px 4px 0px rgba(0,0,0,0.14),
            0px 0px 4px 0px rgba(0,0,0,0.12)`,
        zIndex: 10,
    },
    toolbar: {
        backgroundColor: theme.palette.techBlack,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        color: 'white',
    },
    toolbarTop: {
        minHeight: 0,
        justifyContent: 'flex-end',
    },
    logoContainer: {
        paddingRight: theme.spacing(4),
        display: 'flex',
    },
    navlink: {
        textDecoration: 'none',
    },
    navDivider: {
        padding: '15px 5px 5px',
    },
    navToplink: {
        textDecoration: 'none',
        color: 'white',
        fontFamily: 'HumanBBYDigital-Medium, sans-serif',
        fontWeight: 500,
        fontSize: '12px',
        padding: '15px 0 5px',
        borderBottom: '2px solid rgba(0, 0, 0, 0)',

        '&:hover': {
            borderBottom: '2px solid white',
        },
        '&[data-active="true"]': {
            borderBottom: '2px solid white',
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    const { authedUser } = useContext(AppContext);
    const featureFlags = useFeatureFlags();
    const [openFlyout, setOpenFlyout] = useState(false);
    let flyoutModel = null;

    const onCloseFlyoutModal = () => {
        setOpenFlyout(false);
    };

    if (authedUser.loadState === RequestStatus.DONE && openFlyout) {
        flyoutModel = (
            <FlyoutModal title="User Profile" open={openFlyout} onClose={onCloseFlyoutModal}>
                <UserDetailContent user={authedUser} />
                {userIsExternal(authedUser) && (
                    <IDAMPasswordUpdateContent
                        userId={authedUser.userId}
                        uuid={authedUser.uuid}
                        onExit={onCloseFlyoutModal}
                    />
                )}
            </FlyoutModal>
        );
    }

    return (
        <div className={classes.root}>
            <div>{flyoutModel}</div>
            <Toolbar className={classnames(classes.toolbar, classes.toolbarTop)}>
                {featureFlags.showDevPage && (
                    <>
                        <NavLink
                            exact
                            to="/auth-dev"
                            data-active={window.location.pathname.includes('/auth-dev')}
                            className={classes.navToplink}
                            data-testid="dev-link"
                        >
                            Development Tools
                        </NavLink>
                        <span className={classes.navDivider}> | </span>
                    </>
                )}
                {hasOrganizationRoles(authedUser) && (
                    <>
                        <NavLink
                            exact
                            to="/management/organization"
                            data-active={window.location.pathname.includes('/management/organization')}
                            className={classes.navToplink}
                            data-testid="organization-link"
                        >
                            Organization
                        </NavLink>
                        <span className={classes.navDivider}> | </span>
                    </>
                )}
                {hasPermission(authedUser, UserManagePermissions) && (
                    <NavLink
                        exact
                        to="/management/user/all"
                        data-active={window.location.pathname.includes('/management/user/all')}
                        className={classes.navToplink}
                    >
                        User Management
                    </NavLink>
                )}
            </Toolbar>
            <Toolbar className={classes.toolbar}>
                <Link href="/dashboard" className={classes.navlink}>
                    <div className={classes.logoContainer}>
                        <LogoSvg />
                    </div>
                </Link>
                <Search />
                <HelpMenu />
                <span>|</span>
                <UserMenu authedUser={authedUser} onClickProfile={() => setOpenFlyout(true)} />
            </Toolbar>
            <Navbar />
        </div>
    );
};

export default Header;
